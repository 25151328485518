import {Container} from "react-bootstrap";
import logoFlatWhite from "../imgs/logo/logo-mgm-flat-white.png";
import {FaAt, FaFacebook, FaInstagram, FaLinkedin, FaPhone} from "react-icons/fa";


export function Footer() {

    return (
        <div>
            <div className="bg-nero py-5 text-center d-flex justify-content-center align-items-center boxFooter" style={{minHeight:'30vh'}}>
                <Container className="pt-5 pb-4 text-light opacity-25">
                    <div className="d-flex flex-column flex-lg-row justify-content-center justify-content-lg-evenly align-items-center align-items-lg-start">
                        <div className="mb-5 mb-lg-0">
                            <img
                                src={logoFlatWhite}
                                alt="Logo MGM"
                                height={75}
                            />
                        </div>
                        <div className="text-center text-lg-start mb-5 mb-lg-0">
                            <div className="small">
                                <p className="fw-bold mb-1">MGM Solar s.r.l.</p>
                                <p>S.S. 28 Sud, 14 - 12084 Mondovì (CN)</p>
                                <a href="tel:+393895122545" target="_blank" rel="noreferrer" style={{color:'#fff'}}>
                                    <p className="mb-1"><FaPhone size="1.2em"/> &nbsp;&nbsp; 389 512 2545</p>
                                </a>
                                <a href="mailto:info@mgmsolar.it" target="_blank" rel="noreferrer" style={{color:'#fff'}}>
                                    <p><FaAt size="1.2em"/> &nbsp;&nbsp; info@mgmsolar.it</p>
                                </a>
                                <div className="mt-2">
                                    <FaFacebook size="1.5em" className="iconHover" />
                                    <FaInstagram size="1.5em" className="mx-2 iconHover" />
                                    <FaLinkedin size="1.5em" className="iconHover" />
                                </div>
                            </div>
                        </div>
                        <div className="text-center text-lg-start">
                            CERTIFICAZIONI
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
}
