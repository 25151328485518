import {Navbar} from "../../Navbar/Navbar";
import {Col, Container, Row} from "react-bootstrap";
import {Footer} from "../../Footer/Footer";
import {MetaDecorator} from "../../utils/MetaDecorator";


export function Pratiche() {
    return (
        <div>
            <MetaDecorator
                title="Pratiche"
                description="La produzione e lo scambio di energia devono rispondere a rigide regolamentazioni e procedure in continuo aggiornamento. Mgm Solar dispone delle competenze e del personale specificamente dedicato alla gestione delle pratiche amministrative, evitandoti sanzioni o altri disagi."
                keywords="gse, pratiche gse, terna, pratiche terna, pratiche amministrative"
            />
            <Navbar />
            <Container fluid className="boxImgCover" style={{height:'60vh', backgroundImage:'url("https://i.ibb.co/qjtTw1F/pratiche-cover.jpg")', backgroundSize:'cover', backgroundPosition:'center'}} />
            <Container className="py-5">
                <div className="text-center mb-0 mb-lg-5 pt-3">
                    <h1 className="fw-bold">Pratiche</h1>
                </div>
                <div className="py-5">
                    <Row className="mt-5">
                        <Col xs={12} lg={7} className="text-center text-lg-start d-flex justify-content-start align-items-center">
                            <div className="mb-5 mb-lg-0">
                                <h2 className="fw-bold mb-5">Pratiche GSE</h2>
                                <p className="mb-5">
                                    <p>I tecnici di MGM Solar  si occupano di tutta la documentazione necessaria per ottenere contributi e incentivi governativi trasmettendoli al GSE: Gestore dei Servizi Energetici.</p>
                                    <br/>
                                    <p>Non dovrai preoccuparti di nulla, Mgm Solar si  occuperà  della redazione delle comunicazioni obbligatorie, delle pratiche di incentivazione e dei rapporti con il GSE per l’installazione di sistemi di produzione di energia elettrica da fotovoltaico, dallo scambio sul posto  posto (SSP) all’autoconsumo e il ritiro dedicato.</p>
                                    <br/>
                                    <p>Anche chi già possiedie  un impianto fotovoltaico viene  sottoposto periodicamente ad un controllo del sistema da parte del <b>Gestore dei Servizi Energetici (GSE)</b> per stabilire il prolungamento della concessione o  la negazione dell’erogazione degli incentivi in base ad alcuni parametri dell’impianto fotovoltaico.</p>
                                    <br/>
                                    <p>Proprio per questo, analizzare la documentazione, risulta fondamentale per identificare alcune irregolarità.</p>
                                    <br/>
                                    <p>AFFIDATI A NOI DI <b><i>Mgm Solar</i></b> E METTI VIA OGNI PREOCCUPAZIONE</p>
                                </p>
                            </div>
                        </Col>
                        <Col xs={12} lg={5} className="text-end d-flex justify-content-center align-items-center">
                            <img
                                src="https://i.ibb.co/hB9Sd5W/gse.jpg"
                                alt="Chi Siamo"
                                className="img4corners imgBig"
                            />
                        </Col>
                    </Row>
                </div>
                <div className="py-5">
                    <Row className="mt-5">
                        <Col xs={12} lg={5} className="text-start d-flex justify-content-center align-items-center order-2 order-lg-1">
                            <img
                                src="https://i.ibb.co/F4mxrHJ/terna.jpg"
                                alt="Chi Siamo"
                                className="img4corners imgBig"
                            />
                        </Col>
                        <Col xs={12} lg={7} className="text-center text-lg-end d-flex justify-content-start align-items-center order-1 order-lg-2">
                            <div className="mb-5 mb-lg-0">
                                <h2 className="fw-bold mb-5">Pratiche TERNA</h2>
                                <p className="mb-5">
                                    <p>Terna è un grande operatore di reti di trasmissione dell'energia. Gestisce la trasmissione di  energia elettrica  in alta tensione in Italia, garantendone nel tempo sicurezza, qualità ed economicità. Gli impianti fotovoltaici devono soddisfare requisiti tecnici minimi per poter gestire in sicurezza il sistema elettrico nazionale.</p>
                                    <br/>
                                    <p>Mgm Solar ti garantisce il rispetto delle prescrizioni Terna e degli ultimi protocolli normativi.</p>
                                </p>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="py-5">
                    <Row className="mt-5">
                        <Col xs={12} lg={7} className="text-center text-lg-start d-flex justify-content-start align-items-center">
                            <div className="mb-5 mb-lg-0">
                                <h2 className="fw-bold mb-5">Pratiche amministrative</h2>
                                <p className="mb-5">
                                    <p>La produzione e lo scambio di energia devono rispondere a rigide regolamentazioni e procedure in continuo aggiornamento.</p>
                                    <br/>
                                    <p>Mgm Solar dispone delle competenze e del personale specificamente dedicato alla gestione delle pratiche amministrative, evitandoti sanzioni o altri disagi.</p>
                                    <br/>
                                    <ul>
                                        <li>Pratiche ARERA</li>
                                        <li>Pratiche Agenzia delle Dogane</li>
                                        <li>Adeguamenti norme CEI016 e CEI021</li>
                                    </ul>
                                </p>
                            </div>
                        </Col>
                        <Col xs={12} lg={5} className="text-end d-flex justify-content-center align-items-center">
                            <img
                                src="https://i.ibb.co/GQghrys/praticheamministrative.jpg"
                                alt="Chi Siamo"
                                className="img4corners imgBig"
                            />
                        </Col>
                    </Row>
                </div>
            </Container>

            <Footer />
        </div>
    )
}
