import {useEffect, useState} from "react";
import {Col, Row, Form, Button} from "react-bootstrap";
import logoMGM from "../imgs/logo/logo-mgm-black.png"
import logoMGMwhite from "../imgs/logo/logo-mgm-white.png"
import './styleAreaRiservata.css'
import {FaArrowLeft, FaChartLine, FaPencilAlt} from "react-icons/fa";
import {Link} from "react-router-dom";
import {ManageRealizzazioni} from "./ManageRealizzazioni";
import {AggiungiRealizzazione} from "./AggiungiRealizzazione";


export function AreaRiservata() {

    const [password, setPassword] = useState('')
    const [identified, setIdentified] = useState(false)

    const [activeTab, setActiveTab] = useState('')

    return (
        <div id="areariservata">
            {
                identified ?
                    <Row className="m-0 p-0">
                        <Col xs={12} lg={3} className="p-0">
                            <Sidebar />
                        </Col>
                        <Col xs={12} lg={9} className="p-5">
                            <div className="p-3">
                                {
                                    activeTab==='' ?
                                        <Dashboard />
                                        :
                                        activeTab==='realizzazioni' ?
                                            <ManageRealizzazioni />
                                            :
                                            activeTab==='aggiungiRealizzazione' ?
                                                <AggiungiRealizzazione />
                                                :
                                                null
                                }
                            </div>
                        </Col>
                    </Row>
                    :
                    <div className="d-flex justify-content-center align-items-center" style={{height:'100vh', width:'100vw'}}>
                        <div>
                            <div className="pb-5 text-center text-dark">
                                <img
                                    src={logoMGM}
                                    height={100}
                                    className="mb-3"
                                    alt="logo ThePlace"
                                />
                            </div>
                            <p className="small">Password *</p>
                            <Form.Control
                                type="password"
                                value={password}
                                className="rounded10 mb-3"
                                onChange={e => setPassword(e.target.value)}
                            />
                            <Button
                                variant="dark"
                                className="rounded10 w-100"
                                onClick={() => {
                                    if(password==='mgm') {
                                        setIdentified(true)
                                    }
                                }}
                            >
                                Login
                            </Button>
                        </div>
                    </div>
            }
        </div>
    )

    function Dashboard() {
        return (
            <div>
                <h3 className="fw-bold text-center text-lg-start">Dashboard</h3>

            </div>
        )
    }

    function Sidebar() {

        return (
            <div>
                <div className="sidebarMobile d-block d-lg-none">
                    <div className="py-4 px-4 text-light text-center" style={{backgroundColor: '#111111'}}>
                        <img
                            src={logoMGMwhite}
                            height={75}
                            className="my-4"
                            alt="logo ThePlace"
                        />
                    </div>
                    <Row>
                        {ItemMenu('Dashboard', '', <FaChartLine />, true)}
                        {ItemMenu('Realizzazioni', 'realizzazioni', <FaPencilAlt />, true)}
                        {ItemMenu('Nuova Realizzazione', 'aggiungiRealizzazione', <FaPencilAlt />, true)}
                    </Row>
                </div>
                <div className="sidebarDesktop d-flex flex-column justify-content-between d-none d-lg-block">
                    <div>
                        <div className="py-4 px-4 text-light text-center" style={{backgroundColor: '#111111'}}>
                            <img
                                src={logoMGMwhite}
                                height={75}
                                className="my-4"
                                alt="logo ThePlace"
                            />
                        </div>
                        <div className="py-3">
                            {ItemMenu('Dashboard', '', <FaChartLine />, false)}
                            {ItemMenu('Realizzazioni', 'realizzazioni', <FaPencilAlt />, false)}
                            {ItemMenu('Nuova Realizzazione', 'aggiungiRealizzazione', <FaPencilAlt />, false)}
                        </div>
                    </div>
                    <div>
                        <div className="linkSidebar">
                            <Link to="/">
                                <Row style={{padding: '1.2em 1.5em'}}>
                                    <Col md="auto" className="d-flex justify-content-start align-items-center">
                                        <FaArrowLeft />
                                    </Col>
                                    <Col className="d-flex justify-content-start align-items-center">
                                        <p>home</p>
                                    </Col>
                                </Row>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function ItemMenu(label, link, icon, mobile) {

        if(mobile) {
            return (
                <Col xs={4} className={link===activeTab ? 'linkSidebarActive' : 'linkSidebar'}
                     onClick={() => setActiveTab(link)}
                >
                    <div className="text-center py-3">
                        <div>
                            {icon}
                        </div>
                        <p>{label}</p>
                    </div>
                </Col>
            )
        } else {
            return (
                <div className={link===activeTab ? 'linkSidebarActive' : 'linkSidebar'}
                     onClick={() => setActiveTab(link)}
                >
                    <Row style={{padding: '1.2em 1.5em'}}>
                        <Col md={1} className="d-flex justify-content-start align-items-center">
                            <div>
                                {icon}
                            </div>
                        </Col>
                        <Col className="d-flex justify-content-start align-items-center">
                            <p>{label}</p>
                        </Col>
                        <Col md="auto" className="d-flex justify-content-start align-items-center">
                            >
                        </Col>
                    </Row>
                </div>
            )
        }
    }


}
