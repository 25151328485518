import {Navbar} from "../../Navbar/Navbar";
import {Col, Container, Row} from "react-bootstrap";
import {Footer} from "../../Footer/Footer";
import {MetaDecorator} from "../../utils/MetaDecorator";


export function IspezionePannelliConDrone() {

    return (
        <div>
            <MetaDecorator
                title="Ispezione Pannelli con Drone"
                description=""
                keywords="termografia pannelli, drone, mappatura hot srt, censimento pannelli, analisi performance, performance"
            />
            <Navbar />
            <Container fluid className="boxImgCover" style={{height:'60vh', backgroundImage:'url("https://i.ibb.co/Jqs475K/ispezione-drone-cover.jpg")', backgroundSize:'cover', backgroundPosition:'center'}} />
            <Container className="py-5">
                <div className="text-center mb-0 mb-lg-5 pt-3">
                    <h1 className="fw-bold">Ispezione Pannelli con Drone</h1>
                </div>
                <Row className="py-5">
                    <Col xs={12} lg={7} className="text-center text-lg-start d-flex justify-content-start align-items-center">
                        <div className="mb-5 mb-lg-0">
                            <h3 className="fw-bold mb-5">Termografia e Mappatura Hot-Srt</h3>
                            <p className="mb-5">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                            </p>
                        </div>
                    </Col>
                    <Col xs={12} lg={5} className="text-end d-flex justify-content-center align-items-center">
                        <img
                            src="https://i.ibb.co/ngpCVVc/termografia2.jpg"
                            alt="Chi Siamo"
                            className="img4corners imgBig"
                        />
                    </Col>
                </Row>
                <Row className="py-5">
                    <Col xs={12} lg={5} className="text-start d-flex justify-content-center align-items-center order-2 order-lg-1">
                        <img
                            src="https://i.ibb.co/0ZyLCwb/censimento-pannelli.jpg"
                            alt="Chi Siamo"
                            className="img4corners imgBig"
                        />
                    </Col>
                    <Col xs={12} lg={7} className="text-center text-lg-end d-flex justify-content-start align-items-center order-1 order-lg-2">
                        <div className="mb-5 mb-lg-0">
                            <h3 className="fw-bold mb-5">Censimento Pannelli Fotovoltaici</h3>
                            <p className="mb-5">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                            </p>
                        </div>
                    </Col>
                </Row>
                <Row className="py-5">
                    <Col xs={12} lg={7} className="text-center text-lg-start d-flex justify-content-start align-items-center">
                        <div className="mb-5 mb-lg-0">
                            <h3 className="fw-bold mb-5">Analisi Performance</h3>
                            <p className="mb-5">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                            </p>
                        </div>
                    </Col>
                    <Col xs={12} lg={5} className="text-end d-flex justify-content-center align-items-center">
                        <img
                            src="https://i.ibb.co/YfXC7SV/termografia3.jpg"
                            alt="Chi Siamo"
                            className="img4corners imgBig"
                        />
                    </Col>
                </Row>
            </Container>

            <Footer />
        </div>
    )
}
