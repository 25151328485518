import './navabar.css'
import {Container, Dropdown, Offcanvas} from "react-bootstrap";
import {Link} from "react-router-dom";
import logo from '../imgs/logo/logo-mgm-white.png'
import {FaBars} from "react-icons/fa";
import React, {useState} from 'react'
import {MdMailOutline, MdPhone} from "react-icons/md";


export function Navbar() {

    const [showOffcanvas, setShowOffcanvas] = useState(false)

    return (
        <div>
            {
                // MOBILE NAVBAR
            }
            <div>
                <div className="mainnavbar d-block d-lg-none">
                    <div className="navbar-top">
                        <Container className="d-flex justify-content-between align-items-center px-5 px-lg-0">
                            <div>
                                <Link to="/">
                                    <img
                                        src={logo}
                                        alt="Logo MGM solar"
                                        height={65}
                                    />
                                </Link>
                            </div>
                            <div className="text-light d-flex justify-content-center align-items-center">
                                <FaBars
                                    className="text-green iconHover"
                                    size="2em"
                                    onClick={() => setShowOffcanvas(true)}
                                />
                            </div>
                        </Container>
                    </div>
                </div>
                <Offcanvas show={showOffcanvas} onHide={() => setShowOffcanvas(false)} placement="end" className="bg-green">
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title> </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <div style={{height:'80vh'}} className="d-flex flex-column justify-content-evenly align-items-center text-uppercase">
                            <div>
                                <Link to="/">
                                    Home
                                </Link>
                            </div>
                            <div>
                                <Link to="/chi-siamo">
                                    Chi siamo
                                </Link>
                            </div>
                            <div>
                                <Dropdown>
                                    <Dropdown.Toggle as={CustomToggle}>Servizi </Dropdown.Toggle>
                                    <Dropdown.Menu className="rounded10">
                                        <Dropdown.Item className="small text-dark" disabled>
                                            Fotovoltaico
                                        </Dropdown.Item>
                                        <Dropdown.Item as={Link} to="/servizi/fotovoltaico-su-copertura" className="small text-dark" style={{paddingLeft:'2.5em'}}>
                                            su copertura
                                        </Dropdown.Item>
                                        <Dropdown.Item as={Link} to="/servizi/fotovoltaico-a-terra" className="small text-dark" style={{paddingLeft:'2.5em'}}>
                                            a terra
                                        </Dropdown.Item>
                                        <Dropdown.Item as={Link} to="/servizi/fotovoltaico-su-parcheggio" className="small text-dark" style={{paddingLeft:'2.5em'}}>
                                            su parcheggio
                                        </Dropdown.Item>
                                        <Dropdown.Divider />
                                        <Dropdown.Item as={Link} to="/servizi/colonnine-ricarica" className="small text-dark">
                                            Colonnine ricarica
                                        </Dropdown.Item>
                                        <Dropdown.Item as={Link} to="/servizi/sistema-accumulo" className="small text-dark">
                                            Sistema accumulo
                                        </Dropdown.Item>
                                        <Dropdown.Item as={Link} to="/servizi/rimozione-eternit" className="small text-dark">
                                            Rimozione Eternit
                                        </Dropdown.Item>
                                        <Dropdown.Item as={Link} to="/servizi/noleggio-operativo" className="small text-dark">
                                            Noleggio Operativo
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <div>
                                <Link to="/industria">
                                    Industria
                                </Link>
                            </div>
                            <div>
                                <Link to="/aziende-agricole">
                                    Az. Agricole
                                </Link>
                            </div>
                            <div>
                                <Dropdown>
                                    <Dropdown.Toggle as={CustomToggle}>Casa </Dropdown.Toggle>
                                    <Dropdown.Menu className="rounded10">
                                        <Dropdown.Item as={Link} to="/casa/fotovoltaico" className="small text-dark">
                                            Fotovoltaico
                                        </Dropdown.Item>
                                        <Dropdown.Item as={Link} to="/casa/serramenti-ed-infissi" className="small text-dark">
                                            Serramenti ed Infissi
                                        </Dropdown.Item>
                                        <Dropdown.Item as={Link} to="/casa/ecobonus-110" className="small text-dark">
                                            Ecobonus 110%
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <div>
                                <Dropdown>
                                    <Dropdown.Toggle as={CustomToggle}>Assistenza </Dropdown.Toggle>
                                    <Dropdown.Menu className="rounded10">
                                        <Dropdown.Item as={Link} to="/assistenza/manutenzione" className="small text-dark">
                                            Manutenzione
                                        </Dropdown.Item>
                                        <Dropdown.Item as={Link} to="/assistenza/pratiche" className="small text-dark">
                                            Pratiche
                                        </Dropdown.Item>
                                        <Dropdown.Item as={Link} to="/assistenza/ispezione-pannelli-con-drone" className="small text-dark">
                                            Ispezione pannelli con drone
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <div>
                                <Link to="/realizzazioni">
                                    Realizzazioni
                                </Link>
                            </div>
                            <div>
                                <Link to="/contatti">
                                    Contatti
                                </Link>
                            </div>
                        </div>
                    </Offcanvas.Body>
                </Offcanvas>
            </div>
            {
                // DESKTOP NAVBER
            }
            <div className="mainnavbar d-none d-lg-block text-uppercase">
                <div className="navbar-top">
                    <Container className="d-flex justify-content-between align-items-center">
                        <div>
                            <Link to="/">
                                <img
                                    src={logo}
                                    alt="Logo MGM solar"
                                    height={65}
                                />
                            </Link>
                        </div>
                        <div className="text-light d-flex justify-content-center align-items-center">
                            <Dropdown className="mx-2">
                                <Dropdown.Toggle variant="light" size="sm" className="rounded10 fw-bold">
                                    Media
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="rounded10">
                                    <Dropdown.Item className="small">
                                        Dicono di noi
                                    </Dropdown.Item>
                                    <Dropdown.Item className="small">
                                        Video
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <a href="tel:3390000000" style={{color:'#fff'}}>
                                <MdPhone
                                    className="mx-1 iconHover"
                                    size="1.5em"
                                />
                            </a>
                            <a href="mailto:info@mgmsolar.it" style={{color:'#fff'}}>
                                <MdMailOutline
                                    className="mx-1 iconHover"
                                    size="1.5em"
                                />
                            </a>
                        </div>
                    </Container>
                </div>
                <div className="navabar-bottom">
                    <Container className="d-flex justify-content-between align-items-center">
                        <Link to="/">
                            <p>Home</p>
                        </Link>
                        <Link to="/chi-siamo">
                            <p>Chi siamo</p>
                        </Link>
                        <Dropdown>
                            <Dropdown.Toggle as={CustomToggle}>Servizi </Dropdown.Toggle>
                            <Dropdown.Menu className="rounded10">
                                <Dropdown.Item className="small text-dark" disabled>
                                    <p>Fotovoltaico</p>
                                </Dropdown.Item>
                                <Dropdown.Item as={Link} to="/servizi/fotovoltaico-su-copertura" className="small text-dark" style={{paddingLeft:'2.5em'}}>
                                    <p>su copertura</p>
                                </Dropdown.Item>
                                <Dropdown.Item as={Link} to="/servizi/fotovoltaico-a-terra" className="small text-dark" style={{paddingLeft:'2.5em'}}>
                                    <p>a terra</p>
                                </Dropdown.Item>
                                <Dropdown.Item as={Link} to="/servizi/fotovoltaico-su-parcheggio" className="small text-dark" style={{paddingLeft:'2.5em'}}>
                                    <p>su parcheggio</p>
                                </Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item as={Link} to="/servizi/colonnine-ricarica" className="small text-dark">
                                    <p>Colonnine ricarica</p>
                                </Dropdown.Item>
                                <Dropdown.Item as={Link} to="/servizi/sistema-accumulo" className="small text-dark">
                                    <p>Sistema accumulo</p>
                                </Dropdown.Item>
                                <Dropdown.Item as={Link} to="/servizi/rimozione-eternit" className="small text-dark">
                                    <p>Rimozione Eternit</p>
                                </Dropdown.Item>
                                <Dropdown.Item as={Link} to="/servizi/noleggio-operativo" className="small text-dark">
                                    <p>Noleggio Operativo</p>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <Link to="/industria">
                            <p>Industria</p>
                        </Link>
                        <Link to="/aziende-agricole">
                            <p>Az. Agricole</p>
                        </Link>
                        <Dropdown>
                            <Dropdown.Toggle as={CustomToggle}>Casa </Dropdown.Toggle>
                            <Dropdown.Menu className="rounded10">
                                <Dropdown.Item as={Link} to="/casa/fotovoltaico" className="small text-dark">
                                    <p>Fotovoltaico</p>
                                </Dropdown.Item>
                                <Dropdown.Item as={Link} to="/casa/serramenti-ed-infissi" className="small text-dark">
                                    <p>Serramenti ed Infissi</p>
                                </Dropdown.Item>
                                <Dropdown.Item as={Link} to="/casa/ecobonus-110" className="small text-dark">
                                    <p>Ecobonus 110%</p>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown>
                            <Dropdown.Toggle as={CustomToggle}>Assistenza </Dropdown.Toggle>
                            <Dropdown.Menu className="rounded10">
                                <Dropdown.Item as={Link} to="/assistenza/manutenzione" className="small text-dark">
                                    <p>Manutenzione</p>
                                </Dropdown.Item>
                                <Dropdown.Item as={Link} to="/assistenza/pratiche" className="small text-dark">
                                    <p>Pratiche</p>
                                </Dropdown.Item>
                                <Dropdown.Item as={Link} to="/assistenza/ispezione-pannelli-con-drone" className="small text-dark">
                                    <p>Ispezione pannelli con drone</p>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <Link to="/realizzazioni">
                            <p>Realizzazioni</p>
                        </Link>
                        <Link to="/contatti" className="text-light">
                            <p>Contatti</p>
                        </Link>
                    </Container>
                </div>
            </div>
        </div>
    )
}

/*

<div className="mainnavbar">
            <div className="navbar-top">
                <Container className="d-flex justify-content-between align-items-center">
                    <div>
                        <img
                            src={logo}
                            alt="Logo MGM solar"
                            height={65}
                        />
                    </div>
                    <div className="text-light d-flex justify-content-center align-items-center">
                        <Dropdown className="mx-2">
                            <Dropdown.Toggle variant="light" size="sm" className="rounded10 fw-bold">
                                Media
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="rounded10">
                                <Dropdown.Item className="small">
                                    Dicono di noi
                                </Dropdown.Item>
                                <Dropdown.Item className="small">
                                    Video
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <FaPhone
                            className="mx-2 iconHover"
                            size="1.3em"
                        />
                        <FaPaperPlane
                            className="mx-2 iconHover"
                            size="1.3em"
                        />
                        <div className="mx-4" />
                        <Button
                            size="sm"
                            className="rounded10 mx-2"
                            variant="danger"
                            disabled
                        >
                            Area Riservata
                        </Button>
                    </div>
                </Container>
            </div>
            <div className="navabar-bottom">
                <Container className="d-flex justify-content-between align-items-center">
                    <Link to="/">
                        Home
                    </Link>
                    <Link to="/chi-siamo">
                        Chi siamo
                    </Link>
                    <Dropdown>
                        <Link to="/servizi">
                            <Dropdown.Toggle as={CustomToggle}>Servizi </Dropdown.Toggle>
                        </Link>
                        <Dropdown.Menu className="rounded10">
                            <Dropdown.Item className="small text-dark" disabled>
                                Fotovoltaico
                            </Dropdown.Item>
                            <Dropdown.Item className="small text-dark" style={{paddingLeft:'2.5em'}}>
                                su copertura
                            </Dropdown.Item>
                            <Dropdown.Item className="small text-dark" style={{paddingLeft:'2.5em'}}>
                                a terra
                            </Dropdown.Item>
                            <Dropdown.Item className="small text-dark" style={{paddingLeft:'2.5em'}}>
                                su parcheggio
                            </Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item className="small text-dark">
                                Colonnine ricarica
                            </Dropdown.Item>
                            <Dropdown.Item className="small text-dark">
                                Sistemi accumulo
                            </Dropdown.Item>
                            <Dropdown.Item className="small text-dark">
                                Rimozione eternit
                            </Dropdown.Item>
                            <Dropdown.Item className="small text-dark">
                                Termografia con drone
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <Link to="/industria">
                        Industria
                    </Link>
                    <Link to="/azienda-agricola">
                        Az. Agricola
                    </Link>
                    <Dropdown>
                        <Link to="/casa">
                            <Dropdown.Toggle as={CustomToggle}>Casa </Dropdown.Toggle>
                        </Link>
                        <Dropdown.Menu className="rounded10">
                            <Dropdown.Item className="small text-dark">
                                Fotovoltaico
                            </Dropdown.Item>
                            <Dropdown.Item className="small text-dark">
                                Infissi
                            </Dropdown.Item>
                            <Dropdown.Item className="small text-dark">
                                Ecobonus 110%
                            </Dropdown.Item>
                            <Dropdown.Item className="small text-dark">
                                Termografia con drone
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown>
                        <Link to="/assistenza">
                            <Dropdown.Toggle as={CustomToggle}>Assistenza </Dropdown.Toggle>
                        </Link>
                        <Dropdown.Menu className="rounded10">
                            <Dropdown.Item className="small text-dark">
                                Manutenzione
                            </Dropdown.Item>
                            <Dropdown.Item className="small text-dark">
                                Pratiche
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <Link to="/realizzazioni">
                        Realizzazioni
                    </Link>
                    <Link to="/contatti" className="text-light">
                        Contatti
                    </Link>
                </Container>
            </div>
        </div>


 */

export const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
        href=""
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
        &#x25bc;
    </a>
));
