import {Navbar} from "../../Navbar/Navbar";
import {Col, Container, Row} from "react-bootstrap";
import {Footer} from "../../Footer/Footer";
import {MetaDecorator} from "../../utils/MetaDecorator";


export function RimozioneEternit() {

    return (
        <div>
            <MetaDecorator
                title="Rimozione Eternit"
                description="L'eternit contenente è un materiale di origine naturale con struttura fibrosa contenente cemento ed un insieme di minerali che prendono il nome di amianto."
                keywords="rimozione amianto, eternit, obbligatorio, tumori, rifacimento tetto, tetto, nocivo"
            />
            <Navbar />
            <Container fluid className="boxImgCover" style={{height:'60vh', backgroundImage:'url("https://i.ibb.co/8Dp6Ysv/eternit-cover.jpg")', backgroundSize:'cover', backgroundPosition:'center'}} />
            <Container className="py-5">
                <div className="text-center  pt-3">
                    <h1 className="fw-bold">Bonifica Amianto</h1>
                </div>
                <div className="py-5">
                    <Row className="mt-5">
                        <Col xs={12} lg={5} className="text-start d-flex justify-content-center align-items-center order-2 order-lg-1">
                            <img
                                src="https://i.ibb.co/tDLpnpB/eternit-1.jpg"
                                alt="Chi Siamo"
                                className="img4corners imgBig"
                            />
                        </Col>
                        <Col xs={12} lg={7} className="text-center text-lg-end d-flex justify-content-start align-items-center order-1 order-lg-2">
                            <div className="mb-5 mb-lg-0">
                                <h2 className="fw-bold mb-5">Smaltimento Eternit</h2>
                                <p className="mb-5">
                                    <p>L'<b>eternit</b> contenente è un materiale di origine naturale con struttura fibrosa contenente cemento ed un insieme di minerali che prendono il nome di <b>amianto</b>.</p>
                                    <br />
                                    <p>Questo materiale non è in sè pericoloso, ma lo diventa nel momento in cui inizia a degradarsi poichè l'amianto, se danneggiato o polverizzato, libera nell'aria delle particelle estrememante dannose per la salute umana che, se inalate,  provocano malattie dell'apparato respiratorio come ad esempio <b>tumori</b>.</p>
                                    <br />
                                    <p>Per questo motivo è stato <b>vietato</b> in Italia ed è inoltre <b>obbligatorio</b> procedere allo smaltimento  con particolare urgenza se lo stesso con il tempo è divenuto friabile.</p>
                                    <br />
                                    <p>Rimettere a norma una copertura però ora è soprattutto <b>un’occasione di riqualificazione e rivalutazione dello stabile</b> che ti permette di  <b>aumentare il valore dell’immobile</b> attraverso semplici interventi di efficientamento energetico.</p>
                                    <br />
                                    <p>Mgm Solar è  specializzata nella rimozione,  bonifica e smaltimento dell’amianto ti accompagnerà fianco a fianco evitando conseguenze penali e di salute.</p>
                                </p>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="py-5">
                    <Row className="mt-5">
                        <Col xs={12} lg={7} className="text-center text-lg-start d-flex justify-content-start align-items-center">
                            <div className="mb-5 mb-lg-0">
                                <h2 className="fw-bold mb-5">Rifacimento Tetto</h2>
                                <p className="mb-5">
                                    <p>Grazie ai nostri parnter possiamo eliminare l’amianto e installare l’impianto fotovoltaico in un’unica operazione, semplificando le tempistiche di lavorazione e consentendoti un grande risparmio energetico.</p>
                                    <br />
                                    <p>Il solo rifacimento del tetto, infatti, costituirebbe una spesa senza ritorno economico. Invece, scegliendo di installare pannelli solari contestualmente all’intervento sul tetto, si otterranno quattro grandi vantaggi:</p>
                                    <br />
                                    <ul>
                                        <li>Un tetto nuovo senza amianto nel rispetto delle normative vigenti e della salute dei lavoratori.</li>
                                        <li>Risparmio energetico ed economico: grazie all’autoproduzione di energia dei pannelli solari in pochi anni potrai rientrare nelle spese di rifacimento del tetto e di installazione dell’impianto.</li>
                                        <li>Energia green e pulita per la tua azienda: sfruttando l’energia del sole per la tua impresa contribuirai alla riduzione delle emissioni di CO2 in atmosfera.</li>
                                        <li>Detrazioni fiscali: sostituendo il tetto in amianto è possibile beneficiare delle <b>detrazioni fiscali previste dall’EcoBonus</b>: richiedi a noi e ai nostri partner una valutazione dello stato del tuo tetto, e scopri se puoi usufruire delle detrazioni fino al 65%.</li>
                                    </ul>
                                </p>
                            </div>
                        </Col>
                        <Col xs={12} lg={5} className="text-end d-flex justify-content-center align-items-center">
                            <img
                                src="https://i.ibb.co/6RLtF2Z/eternit-2.jpg"
                                alt="Chi Siamo"
                                className="img4corners imgBig"
                            />
                        </Col>
                    </Row>
                </div>
            </Container>

            <Container fluid className="bg-green p-5 text-light text-center mb-5">
                <Container className="my-5">
                    <h5 className="fw-bold mb-3">Da un problema serio a un’occasione imperedibile: lo smaltimento di coperture nocive non è mai stato così facile!</h5>
                    <p>Dall’amianto al fotovoltaico: scopri come trasformare un problema in un investimento strategico per la tua azienda.</p>
                </Container>
            </Container>

            <Footer />
        </div>
    )
}
