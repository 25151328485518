import {Navbar} from "../Navbar/Navbar";
import {Container} from "react-bootstrap";
import {Footer} from "../Footer/Footer";


export function NotFound404() {

    return (
        <div>
            <Navbar />

            <div className="bg-light py-5 text-center d-flex justify-content-center align-items-center" style={{minHeight:'50vh'}}>
                <Container className="py-5">
                    <h1 className="fw-bold">404</h1>
                    <p className="fw-bold">Pagina non trovata</p>
                </Container>
            </div>

            <Footer />
        </div>
    )
}
