import {Navbar} from "../../Navbar/Navbar";
import {Col, Container, Row} from "react-bootstrap";
import {Footer} from "../../Footer/Footer";
import {MetaDecorator} from "../../utils/MetaDecorator";


export function Manutenzione() {
    return (
        <div>
            <MetaDecorator
                title="Manutenzione"
                description="La manutenzione degli impianti fotovoltaici è uno dei servizi più importanti che offriamo ai nostri clienti e permette di ridurre al minimo le perdite di energia dovute ai malfunzionamenti e ai depositi atmosferici."
                keywords="manutenzione, ordinaria, straordinaria, interventi, lavaggio pannelli, pulizia pannelli, monitoraggio, monitoraggio pannelli"
            />
            <Navbar />
            <Container fluid className="boxImgCover" style={{height:'60vh', backgroundImage:'url("https://i.ibb.co/30vMx8w/manutenzione-cover.jpg")', backgroundSize:'cover', backgroundPosition:'center'}} />
            <Container className="py-5">
                <div className="text-center mb-0 mb-lg-5 pt-3">
                    <h1 className="fw-bold">Manutenzione</h1>
                </div>
                <div className="py-5">
                    <Row className="mt-5">
                        <Col xs={12} lg={7} className="text-center text-lg-start d-flex justify-content-start align-items-center">
                            <div className="mb-5 mb-lg-0">
                                <h2 className="fw-bold mb-5">Ordinaria manutenzione</h2>
                                <p className="mb-5">
                                    <p>La <b>manutenzione degli impianti fotovoltaici</b> è uno dei servizi più importanti che offriamo ai nostri clienti e permette di ridurre al minimo le perdite di energia dovute ai malfunzionamenti e ai depositi atmosferici.</p>
                                    <br />
                                    <p>Grazie al lavoro dei nostri tecnici che ogni giorno analizzano i dati e elaborano le immagini provenienti dal campo, siamo in grado di misureranno lo stato di salute del tuo impianto e programmare le attività di manutenzione.</p>
                                    <p>Dai moduli fotovoltaici agli inverter solari, dai quadri elettrici ai sistemi di sicurezza, il nostro personale qualificato ed addestrato secondo le più stringenti procedure di qualità e sicurezza si prende cura di ogni componente dell’impianto.</p>
                                    <br />
                                    <p>MGM Solar si contraddistingue nella manutenzione grazie alle <b>scansioni termografiche aeree</b>, condotte tramite droni con videocamere a infrarossi, con mappatura termica dell’impianto e analisi di eventuali anomalie, assistenza pratiche, pacchetti assicurativi e molto altro.</p>
                                    <br />
                                    <p>MGM Solar si occupa di <b>interventi di manutenzione straordinaria</b> quali:</p>
                                    <ul>
                                        <li>Riparazione o sostituzione pannelli danneggiati</li>
                                        <li>Sostituzione di inverter e trasformatori</li>
                                        <li>Sostituzione di strutture deteriorate o che hanno subito cedimenti</li>
                                        <li>Riparazione o sotituzione quadri di parallelo sringhe</li>
                                    </ul>
                                    <br/>
                                    <p>Se il tuo <b>impianto fotovoltaico è stato danneggiato</b> o hai rilevato un <b>calo delle prestazioni</b> puoi richiedere la consulenza dei tecnici di Mgm Solar: contattaci per avere maggiori informazioni.</p>
                                </p>
                            </div>
                        </Col>
                        <Col xs={12} lg={5} className="text-end d-flex justify-content-center align-items-center">
                            <img
                                src="https://i.ibb.co/jgSXD7L/manutenzione-3.jpg"
                                alt="Chi Siamo"
                                className="img4corners imgBig"
                            />
                        </Col>
                    </Row>
                </div>
                <div className="py-5">
                    <Row className="mt-5">
                        <Col xs={12} lg={5} className="text-start d-flex justify-content-center align-items-center order-2 order-lg-1">
                            <img
                                src="https://i.ibb.co/PwGZ1TW/manutenzione2.jpg"
                                alt="Chi Siamo"
                                className="img4corners imgBig"
                            />
                        </Col>
                        <Col xs={12} lg={7} className="text-center text-lg-end d-flex justify-content-start align-items-center order-1 order-lg-2">
                            <div className="mb-5 mb-lg-0">
                                <h2 className="fw-bold mb-5">Lavaggio e pulizia</h2>
                                <p className="mb-5">
                                    <p>Pulizia e manutenzione dei pannelli sono fondamentali per avere sempre una resa dei moduli ottimale e per garantire una vita più lunga all’impianto.</p>
                                    <br/>
                                    <p>Periodicamente sui pannelli si depositano foglie, polvere e micro-polveri, polline e simili, che riducono la capacità di assorbimento di luce dei pannelli riducendo il loro rendimento fino al 30%. Per i nostri lavaggi utilizziamo un macchinario robotizzato che permette di pulire a fondo i moduli e di sgrassarli, utilizzando la migliore tecnologia presente sul mercato, servendoci di prodotti e detergenti specifici per evitare il danneggiamento dei moduli.</p>
                                    <br/>
                                    <p>Nel nostro contratto di manutenzione effettueremo la pulizia periodica dell’impianto <b>1 volta all’anno</b> prima della stagione più soleggiata oppure a seguito di un calo di rendimento.</p>
                                    <br/>
                                    <p>Non buttare via la tua energia, prenditi cura del tuo impianto fotovoltaico, contattaci subito.</p>
                                </p>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="py-5">
                    <Row className="mt-5">
                        <Col xs={12} lg={7} className="text-center text-lg-start d-flex justify-content-start align-items-center">
                            <div className="mb-5 mb-lg-0">
                                <h2 className="fw-bold mb-5">Monitoraggio</h2>
                                <p className="mb-5">
                                    <p>Monitorare l’impianto fotovoltaico è vitale per la <b>buona riuscita dell’investimento</b>.</p>
                                    <br/>
                                    <p>Grazie ai nostri tecnici, riusciamo a tenere sotto controllo in tempo reale  l’operatività dell’impianto e le condizioni ambientali (irraggiamento, temperatura, ecc.), avvisandoti tempestivamente in caso di problemi.</p>
                                    <br/>
                                    <p>Monitorando attentamente il sistema, possiamo sapere sempre, e in modo esatto:</p>
                                    <ul>
                                        <li>quanta energia stiamo producendo</li>
                                        <li>quanta ne stiamo auto-consumando</li>
                                        <li>quanta ne stiamo scambiando (cioè re-immettendo in rete tramite SSP)</li>
                                        <li>quale fascia oraria è la più produttiva</li>
                                        <li>se qualche pannello non sta performando come dovrebbe</li>
                                    </ul>
                                    <br />
                                    <p>Ciò consente di migliorare la propria percentuale di autoconsumo.</p>
                                    <br />
                                    <p>Guasto, furti o atti vandalici possono farti perdere molta energia e pesare sul bilancio economico dell'impianto.</p>
                                    <p>Con il monitoraggio da remoto potremo tenere costantemente sotto controllo tutti i e dati per ottenere sempre il massimo dal tuo impianto. </p>
                                </p>
                            </div>
                        </Col>
                        <Col xs={12} lg={5} className="text-end d-flex justify-content-center align-items-center">
                            <img
                                src="https://i.ibb.co/Pt5QfkB/manutenzione-1.jpg"
                                alt="Chi Siamo"
                                className="img4corners imgBig"
                            />
                        </Col>
                    </Row>
                </div>
            </Container>

            <Footer />
        </div>
    )
}
