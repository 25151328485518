import {Navbar} from "../Navbar/Navbar";
import {Button, Col, Container, Form, Row, Spinner} from "react-bootstrap";
import {Footer} from "../Footer/Footer";
import {FaAt, FaFacebook, FaInstagram, FaLinkedin, FaPhone} from "react-icons/fa";
import {MetaDecorator} from "../utils/MetaDecorator";
import * as emailjs from "emailjs-com";
import {useState} from "react";


export function Contatti() {

    const [nome, setNome] = useState('')
    const [email, setEmail] = useState('')
    const [oggetto, setOggetto] = useState('')
    const [messaggio, setMessaggio] = useState('')

    const [loading, setLoading] = useState(false)
    const [result, setResult] = useState('')

    return (
        <div>
            <MetaDecorator
                title="Contatti"
                description="Richiedi un preventivo gratuito o contattaci per avere qualunque informazione."
                keywords="dove siamo, contatti, telefono, numero verde, mail, form, preventivo, gratis, mappa, sede"
            />
            <Navbar />
            <div className="bg-light pt-5 pb-2 text-center d-flex justify-content-center align-items-center" style={{minHeight:'50vh'}}>
                <Container className="py-5">
                    <Container className="py-5">
                        <Row>
                            <Col xs={12} md={6} className="d-flex justify-content-center justify-content-md-start align-items-center text-center text-md-start">
                                <div>
                                    <h1 className="fw-bold mb-5">Contatti</h1>
                                    <div>
                                        <p className="fw-bold mb-1">MGM Solar s.r.l.</p>
                                        <p className="mb-2">S.S. 28 Sud, 14 - 12084 Mondovì (CN)</p>
                                        <a href="tel:+393390000000" target="_blank" rel="noreferrer"
                                           style={{color: '#000'}}>
                                            <p className="mb-1"><FaPhone size="1.2em"/> &nbsp;&nbsp; 339 000 0000</p>
                                        </a>
                                        <a href="mailto:info@mgmsolar.it" target="_blank" rel="noreferrer"
                                           style={{color: '#000'}}>
                                            <p><FaAt size="1.2em"/> &nbsp;&nbsp; info@mgmsolar.it</p>
                                        </a>
                                        <div className="mt-2">
                                            <FaFacebook size="1.5em" className="iconHover"/>
                                            <FaInstagram size="1.5em" className="mx-2 iconHover"/>
                                            <FaLinkedin size="1.5em" className="iconHover"/>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} md={6} className="text-end d-flex justify-content-center align-items-center d-none d-md-block">
                                <img
                                    src="https://i.ibb.co/JtsxzbM/chisiamo-pic.png"
                                    alt="Chi Siamo"
                                    style={{width:'30vw'}}
                                />
                            </Col>
                        </Row>
                    </Container>
                    <Container className="py-5 mt-4 text-start">
                        <div className="text-center mb-5">
                            <h3 className="fw-bold mb-2">Richiedi un Preventivo <u>Gratuito</u></h3>
                            <p>Compila il form qui sotto.</p>
                        </div>
                        <Row>
                            <Col md={5}>
                                <Form.Group className="mb-2">
                                    <Form.Label className="m-1 small">Nome *</Form.Label>
                                    <Form.Control
                                        type="text"
                                        className="rounded10"
                                        value={nome}
                                        onChange={e => setNome(e.target.value)}
                                        disabled={loading}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={7}>
                                <Form.Group className="mb-2">
                                    <Form.Label className="m-1 small">Email *</Form.Label>
                                    <Form.Control
                                        type="email"
                                        className="rounded10"
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                        disabled={loading}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Form.Group className="mb-2">
                            <Form.Label className="m-1 small">Oggetto *</Form.Label>
                            <Form.Control
                                type="text"
                                className="rounded10"
                                value={oggetto}
                                onChange={e => setOggetto(e.target.value)}
                                disabled={loading}
                            />
                        </Form.Group>
                        <Form.Group className="mb-2">
                            <Form.Label className="m-1 small">Messaggio *</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={5}
                                className="rounded10"
                                value={messaggio}
                                onChange={e => setMessaggio(e.target.value)}
                                disabled={loading}
                            />
                        </Form.Group>
                        <div className="pt-2 text-end">
                            {
                                result ?
                                    <span className="fw-bold">{result} &nbsp;&nbsp;</span>
                                    :
                                    null
                            }
                            {
                                loading ?
                                    <Spinner animation="border" variant="dark" size="sm" className="mx-3"/>
                                    :
                                    null
                            }
                            <Button
                                variant="dark"
                                className="rounded10 px-4"
                                disabled={nome.trim()==='' || email.trim()==='' || oggetto.trim()==='' || messaggio.trim()==='' || loading}
                                onClick={() => {
                                    setLoading(true)
                                    sendEmail()
                                }}
                            >
                                Invia
                            </Button>
                        </div>
                    </Container>
                </Container>
            </div>
            {
                /*
                <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d90168.54179836808!2d7.600049680607457!3d45.07023883257609!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47886d126418be25%3A0x8903f803d69c77bf!2sTorino%20TO!5e0!3m2!1sit!2sit!4v1654089166329!5m2!1sit!2sit"
                style={{width:'100vw', height:'40vh', border:'none'}}
                className="p-0 m-0"
            />
                 */
            }

            <Footer />
        </div>
    )

    function sendEmail() {
        const params = {
            fromemail: email,
            fromname: nome,
            toemail: 'info@mgmsolar.it',
            oggetto: 'Form contatti sito - ' + oggetto,
            corpo: messaggio,
        }

        emailjs.send('service_22buacj', 'template_vjvvk3y', params, 'aj_TwSe0vKl6P7nhu').then(() => {
            setNome('')
            setEmail('')
            setOggetto('')
            setMessaggio('')
            setResult('Messaggio inviato.')
            setLoading(false)
        }).catch(() => {
            setResult('Si è verificato un errore.')
            setLoading(false)
        })
    }
}
