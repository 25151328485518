import {Navbar} from "../../Navbar/Navbar";
import {Col, Container, Row} from "react-bootstrap";
import {Footer} from "../../Footer/Footer";
import {MetaDecorator} from "../../utils/MetaDecorator";


export function ColonnineRicarica() {

    return (
        <div>
            <MetaDecorator
                title="Colonnine di Ricarica"
                description="L'energia solare diventa carburante ecosostenbile ed economico."
                keywords="colonnine ricarica, tesla, wallbox, energia rinnovabile"
            />
            <Navbar />
            <Container fluid className="boxImgCover" style={{height:'60vh', backgroundImage:'url("https://i.ibb.co/qJtfw3Q/colonnine-cover.jpg")', backgroundSize:'cover', backgroundPosition:'center'}} />
            <Container className="py-5">
                <div className="text-center mb-0 mb-lg-5 pt-3">
                    <h1 className="fw-bold">Colonnine di Ricarica</h1>
                </div>
                <div className="py-5">
                    <Row className="mt-5">
                        <Col xs={12} lg={5} className="text-start d-flex justify-content-center align-items-center order-2 order-lg-1">
                            <img
                                src="https://i.ibb.co/0p7VGKH/colonnine-1.jpg"
                                alt="Chi Siamo"
                                className="img4corners imgBig"
                            />
                        </Col>
                        <Col xs={12} lg={7} className="text-center text-lg-end d-flex justify-content-start align-items-center order-1 order-lg-2">
                            <div className="mb-5 mb-lg-0">
                                <h3 className="fw-bold">L'energia solare diventa carburante ecosostenbile ed economico.</h3>
                                <br />
                                <p>
                                    È solo questione di tempo, la mobilità sta cambiando rapidamente e il mondo avrà sempre più veicoli elettrici sulle strade, che permetteranno a tutti di muoversi in modo più sostenibile ed ecologico, a zero emissioni, grazie al fotovoltaico e all'elettrico colonne e maggiore autonomia.
                                    È per questo motivo che l'installazione di impianti e pali fotovoltaici non è solo vantaggiosa per il risparmio energetico in casa, ma anche per la ricarica dei veicoli elettrici.
                                    <br/>
                                    Acquistando un veicolo elettrico l'investimento nel fotovoltaico avrebbe un doppio vantaggio:   abbattere i costi della bolletta e ricaricare il vostro veicolo  gratuitamente con l’energia prodotta.
                                    Il tocco finale? L’aggiunta  di un sistema di accumulo che consenta di risparmiare energia solare inutilizzata durante il giorno permettendoti di ricaricare la tua auto anche di notte.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="pb-5">
                    <p className="text-justify">
                        Il mercato delle colonnine elettriche offre diversi tagli di potenze: <b>7,4 kW</b> in monofase e <b>11, 22 e 50 kW</b> in trifase.
                        L’istallazione di una colonnina permette di ricaricare la macchina sfruttando l’elettricità prodotta dall’impianto fotovoltaico e inoltre riduce drasticamente i tempi di ricarica.
                        Per esempio con una potenza di circa <b>22Kw</b> la ricarica avviene in solo <b>2 ore</b>. Oppure con colonnine da <b>50 kW</b> è possibile ricaricare l’<b>80% dell’autonomia dell’autovettura</b> in soli <b>15 minuti</b>.
                    </p>
                    <br/>
                    <p>
                        Esistono principalmente due tipi di dispositivi:
                        <ul>
                            <li>
                                le <b>wallbox</b>, ovvero il sistema di ricarica domestico fissato direttamente al muro
                            </li>
                            <li>
                                le <b>colonnine di ricarica</b> più professionali che sono invece ancorate a terra.
                            </li>
                        </ul>
                    </p>
                </div>

                <Row className="my-4 d-flex justify-content-center align-items-center">
                    <Col xs={12} md="auto" className="mb-5 text-center">
                        <img
                            src={"https://i.ibb.co/PGS0sdk/wallbox.jpg"}
                            alt="img_servizio_01"
                            className="mb-4 img4corners"
                        />
                        <div>
                            <h3 className="fw-bold">Wallbox</h3>
                        </div>
                    </Col>
                    <Col xs={12} md="auto" className="mb-5 text-center">
                        <img
                            src={"https://i.ibb.co/xjNtcNP/colonnina.jpg"}
                            alt="img_servizio_01"
                            className="mb-4 img4corners"
                        />
                        <div>
                            <h3 className="fw-bold">Colonnina di ricarica</h3>
                        </div>
                    </Col>
                </Row>
            </Container>

            <Footer />
        </div>
    )
}
