import {Navbar} from "../../Navbar/Navbar";
import {Col, Container, Row} from "react-bootstrap";
import {Footer} from "../../Footer/Footer";
import {MetaDecorator} from "../../utils/MetaDecorator";


export function FotovoltaicoATerra() {

    return (
        <div>
            <MetaDecorator
                title="Fotovoltaico a Terra"
                description="Valorizza il tuo terreno con il fotovoltaico: facciamo noi l'investimento al posto tuo."
                keywords="fotovoltaico, fotovoltaico a terra, fotovoltaico campi, aziende agricole, terreni industriali"
            />
            <Navbar />
            <Container fluid className="boxImgCover" style={{height:'60vh', backgroundImage:'url("https://i.ibb.co/Wn2vyYQ/fotovoltaico-terreno.jpg")', backgroundSize:'cover', backgroundPosition:'center'}} />
            <Container className="py-5">
                <div className="text-center mb-0 mb-lg-5 pt-3">
                    <h1 className="fw-bold">Fotovoltaico a Terra</h1>
                    <h4 className="mt-2">Valorizza il tuo terreno con il fotovoltaico: facciamo noi l'investimento al posto tuo.</h4>
                </div>
                <div className="py-5">
                    <Row className="mt-5">
                        <Col xs={12} lg={7} className="text-center text-lg-start d-flex justify-content-start align-items-center">
                            <div className="mb-5 mb-lg-0">
                                <p>
                                    <h3 className="fw-bold">Cerchiamo terreni da acquistare per il Fotovoltaico</h3>
                                    <br/>
                                    <p>Vuoi liberarti di un terreno inutilizzato?</p>
                                    <p><b>Acquistiamo</b> il tuo terreno industriale, utilizziamo l'energia solare per generare <b>energia pulita</b> e ti permettiamo di <b>creare valore da terreni inutilizzati</b>.</p>
                                    <br />
                                    <p>Vuoi assicurarti una rendita per i prossimi 30 anni?</p>
                                    <p><b>Affittiamo</b> il tuo terreno per il fotovoltaico. Puoi ottenere un canone annuale da 2000 EUR a 4000 EUR per ettaro.</p>
                                    <br />
                                    <p>Aumentare la <b>produzione di energia pulita</b> e ridurre i consumi di energia proveniente dalle fonti fossili è una priorità assoluta per poter restituire alle <b>generazioni future un pianeta vivibile</b>. Solo alimentando la <b>transizione energetica</b> raggiungeremo questo obiettivo e per farlo <b>abbiamo bisogno di te</b>.</p>
                                    <br />
                                    <p className="fw-bold">FAI LA SCELTA GIUSTA, SCEGLI MGM SOLAR</p>
                                </p>
                            </div>
                        </Col>
                        <Col xs={12} lg={5} className="text-end d-flex justify-content-center align-items-center">
                            <img
                                src="https://i.ibb.co/r0B7DT2/fotovoltaico-terreno-2.jpg"
                                alt="Chi Siamo"
                                className="img4corners imgBig"
                            />
                        </Col>
                    </Row>
                </div>
                <div className="py-5">
                    <Row className="mt-5">
                        <Col xs={12} lg={5} className="text-start d-flex justify-content-center align-items-center order-2 order-lg-1">
                            <img
                                src="https://i.ibb.co/QrrCQGK/suntracer1.jpg"
                                alt="Chi Siamo"
                                className="img4corners imgBig"
                            />
                        </Col>
                        <Col xs={12} lg={7} className="text-center text-lg-end d-flex justify-content-start align-items-center order-1 order-lg-2">
                            <div className="mb-5 mb-lg-0">
                                <h2 className="fw-bold">Scopri se il tuo terreno è idoneo</h2>
                                <br />
                                <p>L’articolo 7 sulle Misure di accelerazione dello sviluppo delle fonti rinnovabili prevede che siano considerate idonee per impianti con moduli a terra:</p>
                                <br />
                                <ul dir="rtl">
                                    <li><b>aree agricole</b> racchiuse in un perimetro i cui punti distino non più di 500 metri da zone a destinazione industriale, artigianale e commerciale, compresi i siti di interesse nazionale, nonché le cave e le miniere</li>
                                    <li><b>aree interne ad impianti industriali e stabilimenti</b> e quelle classificate agricole racchiuse in un perimetro i cui punti distino non più di 500 metri dal medesimo impianto o stabilimento: anche qui, c’è ampliamento della distanza, da 300 a 500 metri</li>
                                    <li><b>aree adiacenti</b> alla rete autostradale entro una distanza non superiore a 300 metri</li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="py-5">
                    <Row className="mt-5">
                        <Col xs={12} lg={7} className="text-center text-lg-start d-flex justify-content-start align-items-center">
                            <div className="mb-5 mb-lg-0">
                                <h2 className="fw-bold">Suntracker</h2>
                                <br />
                                <p>Gli inseguitori solari (o inseguitori solari) sono perfetti per chi cerca una soluzione ad alte prestazioni. I nostri inseguitori monoasse sono in grado di ruotare fino a 55° per seguire il movimento del sole da est a ovest e la procedura retrospettiva consente un aumento del 30-35% della resa del sistema. Il robusto design strutturale ha un perfetto equilibrio del baricentro per limitare le sollecitazioni sul sistema meccanico, garantendone la durata e riducendo al minimo i costi di manutenzione. Il sistema di gestione e controllo è stato sviluppato in collaborazione con ABB.</p>
                            </div>
                        </Col>
                        <Col xs={12} lg={5} className="text-end d-flex justify-content-center align-items-center">
                            <img
                                src="https://i.ibb.co/6Y6qGTT/suntracer3.jpg"
                                alt="Chi Siamo"
                                className="img4corners imgBig"
                            />
                        </Col>
                    </Row>
                </div>
            </Container>

            <Footer />
        </div>
    )
}
