import {Navbar} from "../Navbar/Navbar";
import {Col, Container, Row} from "react-bootstrap";
import {Footer} from "../Footer/Footer";
import {MetaDecorator} from "../utils/MetaDecorator";


export function Industria() {

    return (
        <div>
            <MetaDecorator
                title="Industria"
                description="Mgm Solar si occupa della progettazione e installazione di impianti fotovoltaici su misura per industrie e aziende, in modo da garantire risparmio nei consumi e avviare le imprese sulla strada dell’indipendenza energetica."
                keywords="fotovoltaico per industrie, impianti fotovoltaici aziende, imprese, societa, project manager"
            />
            <Navbar />
            <Container fluid className="boxImgCover" style={{height:'60vh', backgroundImage:'url("https://i.ibb.co/D5H4c62/industria-cover.jpg")', backgroundSize:'cover', backgroundPosition:'center'}} />
            <Container className="py-5">
                <div className="text-center mb-0 mb-lg-5 pt-3">
                    <h1 className="fw-bold">Industria</h1>
                </div>
                <div className="py-5">
                    <Row className="mt-5">
                        <Col xs={12} lg={5} className="text-start d-flex justify-content-center align-items-center order-2 order-lg-1">
                            <img
                                src="https://i.ibb.co/P6cmkP7/industria-1.jpg"
                                alt="Chi Siamo"
                                className="img4corners imgBig"
                            />
                        </Col>
                        <Col xs={12} lg={7} className="text-center text-lg-end d-flex justify-content-start align-items-center order-1 order-lg-2">
                            <div className="mb-5 mb-lg-0">
                                <h2 className="fw-bold mb-5">Investimento per il futuro</h2>
                                <p className="mb-5">
                                    <p>Mgm Solar si occupa  della progettazione e installazione di <b>impianti fotovoltaici su misura per industrie e aziende</b>, in modo da garantire risparmio nei consumi e avviare le imprese sulla strada dell’indipendenza energetica.</p>
                                    <br />
                                    <p>La nostra soluzione “all inclusive” semplicità e convenienza ti permette fin da subito di risparmiare in bolletta producendo la propria energia.</p>
                                    <br />
                                    <p>Mgm Solar  dedica la massima attenzione a ciascun cliente, i nostri tecnici si occuperanno di tutto: dallo studio di fattibilità alla progettazione, dall’installazione alla messa in esercizio dell’impianto.</p>
                                    <br />
                                    <p>Un <b>Project Manager dedicato</b> segue la direzione lavori e il coordinamento delle attività, assicurando il rispetto degli standard qualitativi e dei tempi definiti in fase contrattuale.</p>
                                </p>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="py-5">
                    <Row className="mt-5">
                        <Col xs={12} lg={7} className="text-center text-lg-start d-flex justify-content-start align-items-center">
                            <div className="mb-5 mb-lg-0">
                                <h2 className="fw-bold mb-5">Benefici del Fotovoltaico</h2>
                                <p className="mb-5">
                                    <p>Con un impianto fotovoltaico per azienda permetti alla <b>tua attività di diventare energeticamente più autonoma</b> e usufruire di questi <b>5 principali vantaggi</b>:</p>
                                    <br />
                                    <ul>
                                        <li><b>Abbatti i costi energetici</b>: installando impianti fotovoltaici sul tetto doni alla tua azienda la <b>capacità intelligente</b> di <b>produrre energia elettrica grazie all’irraggiamento del sole</b>, che ti consente di coprire almeno il 60% del tuo fabbisogno energetico attuale.</li>
                                        <li><b>Inizi a risparmiare subito</b>: una volta allacciato, il sistema fotovoltaico per aziende è in grado di <b>generare risparmi fin dal primo mese</b>, abbassando drasticamente il costo della bolletta energetica.</li>
                                        <li><b>Rispetti l’ambiente</b>: le energie rinnovabili non sono il futuro, bensì il presente. Dotarsi di impianti fotovoltaici per aziende, garantendo alla tua attività un sistema di reperimento dell’energia da fonte rinnovabile, significa stare un passo avanti e dare il buon esempio ai tuoi dipendenti e clienti.</li>
                                        <li><b>Rivendi l’energia in eccesso</b>: con lo SSP – Scambio sul Posto – puoi rivendere l’energia che non hai autoconsumato. La compensazione avviene tramite dei conguagli gestiti ed erogati direttamente dal GSE, il Gestore dei Servizi Energetici.</li>
                                    </ul>
                                </p>
                            </div>
                        </Col>
                        <Col xs={12} lg={5} className="text-end d-flex justify-content-center align-items-center">
                            <img
                                src="https://i.ibb.co/6yMDJ81/industria-2.jpg"
                                alt="Chi Siamo"
                                className="img4corners imgBig"
                            />
                        </Col>
                    </Row>
                </div>
            </Container>

            <Footer />
        </div>
    )
}
