import {MetaDecorator} from "../../utils/MetaDecorator";
import {Navbar} from "../../Navbar/Navbar";
import {Col, Container, Row} from "react-bootstrap";
import {Footer} from "../../Footer/Footer";
import {
    FaChartLine,
    FaCog,
    FaFileSignature, FaFireAlt,
    FaHandHoldingUsd, FaShieldAlt, FaThumbsDown, FaThumbsUp
} from "react-icons/fa";
import ImgFrameVerde from '../../imgs/frame_green.png'


export function NoleggioOperativo() {
    return (
        <div>
            <MetaDecorator
                title="Noleggio Operativo"
                description=""
                keywords="noleggio operativo, "
            />
            <Navbar />
            <Container fluid className="boxImgCover" style={{height:'60vh', backgroundImage:'url("https://i.ibb.co/zS7dz7L/noleggio-main.jpg")', backgroundSize:'cover', backgroundPosition:'center'}} />
            <Container className="py-5">
                <div className="text-center mb-5 pt-3">
                    <h1 className="fw-bold">Noleggio Operativo</h1>
                </div>
                <div className="py-5">
                    <Row className="mt-5">
                        <Col xs={12} lg={5} className="text-start d-flex justify-content-center align-items-center order-2 order-lg-1">
                            <img
                                src="https://i.ibb.co/JdS3zZW/noleggio-1.jpg"
                                alt="Chi Siamo"
                                className="img4corners imgBig"
                            />
                        </Col>
                        <Col xs={12} lg={7} className="text-center text-lg-end d-flex justify-content-start align-items-center order-1 order-lg-2">
                            <div className="mb-5 mb-lg-0">
                                <h2 className="fw-bold mb-5">Inizia subito a risparmiare</h2>
                                <p className="mb-3">
                                    Noleggio operativo: un investimento autoliquidante.
                                    <br/>
                                    La migliore soluzione per ogni azienda che vuole abbattere i costi energetici senza alcun esborso iniziale.
                                </p>
                                <h5>
                                    PRODUCI LA TUA ENERGIA E CON IL RISPARMIO FINANZI IL TUO IMPIANTO
                                </h5>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
            <div className="py-5 text-center d-flex justify-content-center align-items-center" style={{minHeight:'50vh', backgroundColor:'#c3cbc9'}}>
                <Container>
                    <div className="mb-5">
                        <h3 className="fw-bold mb-4">I vantaggi del noleggio di un impianto fotovoltaico</h3>
                    </div>
                    <Row className="text-center">
                        <Col xs={12} md={6} lg={4} className="d-flex flex-column justify-content-center align-items-center mb-5">
                            <div className="mb-3">
                                <FaChartLine
                                    size="3em"
                                    className="text-green"
                                />
                            </div>
                            <div className="mx-4 text-center">
                                <p className="mb-2 fw-bold">Flusso di cassa positivo dal primo anno</p>
                                <p>Il risparmio ottenuto sarà sempre maggiore alla rata di noleggio dell’impianto fotovoltaico: già dal primo anno darai un vantaggio alla tua azienda e all’ambiente.</p>
                            </div>
                        </Col>
                        <Col xs={12} md={6} lg={4} className="d-flex flex-column justify-content-center align-items-center mb-5">
                            <div className="mb-3">
                                <FaCog
                                    size="3em"
                                    className="text-green"
                                />
                            </div>
                            <div className="mx-4 text-center">
                                <p className="mb-2 fw-bold">Manutenzione inclusa nel noleggio</p>
                                <p>Curiamo la manutenzione ordinaria e straordinaria dell’impianto, perché siamo i primi interessati al suo perfetto funzionamento.</p>
                            </div>
                        </Col>
                        <Col xs={12} md={6} lg={4} className="d-flex flex-column justify-content-center align-items-center mb-5">
                            <div className="mb-3">
                                <FaHandHoldingUsd
                                    size="3em"
                                    className="text-green"
                                />
                            </div>
                            <div className="mx-4 text-center">
                                <p className="mb-2 fw-bold">Deducibilità al 100% della rata di noleggio</p>
                                <p>Potrai portare a totale deduzione la rata del noleggio sia a fini IRES che ai fini IRAP nello stesso anno contabile.</p>
                            </div>
                        </Col>
                        <Col xs={12} md={6} lg={4} className="d-flex flex-column justify-content-center align-items-center mb-5 mb-lg-3">
                            <div className="mb-3">
                                <FaFileSignature
                                    size="3em"
                                    className="text-green"
                                />
                            </div>
                            <div className="mx-4 text-center">
                                <p className="mb-2 fw-bold">Alle pratiche burocratiche ci pensiamo noi</p>
                                <p>Non avrai nessun pensiero sugli adeguamenti tecnici e sulle pratiche burocratiche obbligatorie, che verranno assolti da noi.</p>
                            </div>
                        </Col>
                        <Col xs={12} md={6} lg={4} className="d-flex flex-column justify-content-center align-items-center mb-5 mb-lg-3">
                            <div className="mb-3">
                                <FaFireAlt
                                    size="3em"
                                    className="text-green"
                                />
                            </div>
                            <div className="mx-4 text-center">
                                <p className="mb-2 fw-bold">Produzione energetica garantita</p>
                                <p>Fin da subito, ti garantiamo quanta energia elettrica verrà prodotta dall’impianto per tutta la durata del contratto di noleggio.</p>
                            </div>
                        </Col>
                        <Col xs={12} md={6} lg={4} className="d-flex flex-column justify-content-center align-items-center mb-5 mb-lg-3">
                            <div className="mb-3">
                                <FaShieldAlt
                                    size="3em"
                                    className="text-green"
                                />
                            </div>
                            <div className="mx-4 text-center">
                                <p className="mb-2 fw-bold">Assicurazione ALL RISK</p>
                                <p>Il pacchetto di noleggio comprende una copertura assicurativa completa per danni all’immobile e all’impianto fotovoltaico.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="py-5 bg-green text-light d-flex justify-content-center align-items-center" style={{minHeight:'50vh'}}>
                <Container>
                    <div className="text-center">
                        <h3 className="fw-bold mb-3">A chi si rivolge</h3>
                        <p>
                            Il noleggio operativo si rivolge a tutte quelle aziende, uffici, strutture ricettive, centri commerciali, e non solo, che utilizzano l’energia prodotta dall’impianto fotovoltaico prevalentemente per l’autoconsumo.
                        </p>
                    </div>
                    <Row className="mt-5 d-flex justify-content-evenly align-items-start">
                        <Col xs={12} md="6" className="d-flex flex-column justify-content-center align-items-center mb-4 mb-md-0">
                            <div className="mb-3">
                                <h4 className="fw-bold">
                                    <FaThumbsUp size="1.25em" className="pb-1" />
                                    &nbsp;&nbsp;A chi interessa
                                </h4>
                            </div>
                            <div>
                                <ul>
                                    <li className="mb-2">
                                        <b>AZIENDE DI PRODUZIONE O ARTIGIANE</b>
                                        <p className="small">Quasi tutte le fabbriche  linee di produzione, aziende artigiane che utilizzano l’energia elettrica per alimentare macchinari di produzione.</p>
                                    </li>
                                    <li className="mb-2">
                                        <b>UFFICI O CENTRI DIREZIONALI</b>
                                        <p className="small">Complessi direzionali e uffici che utilizzano l'energia elettrica per alimentare riscaldare illuminare la struttura.</p>
                                    </li>
                                    <li className="mb-2">
                                        <b>CENTRI COMMERCIALI</b>
                                        <p className="small">Grandi complessi commerciali che utilizzano l’energia per molteplici fini quali:  illuminazione, riscaldamento e raffrescamento, alimentazione utenze, alimentazione impianti sicurezza, ecc.</p>
                                    </li>
                                    <li className="mb-2">
                                        <b>STRUTTURE RICETTIVE</b>
                                        <p className="small">Hotel di medio-grandi dimensioni, Centri Benessere Spa, Centri sportivi, con  importanti consumi di energia elettrica.</p>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col xs={12} md="6" className="d-flex flex-column justify-content-center align-items-center">
                            <div className="mb-3">
                                <h4 className="fw-bold">
                                    <FaThumbsDown size="1.25em" className="pb-1" />
                                    &nbsp;&nbsp;A chi non interessa
                                </h4>
                            </div>
                            <div>
                                <ul>
                                    <li className="mb-2">
                                        <b>RIVENDITA DELL'ENERGIA PRODOTTA</b>
                                        <p className="small">Il noleggio operativo non è così interessante per chi intende installare un impianto fotovoltaico su un appezzamento agricolo o in generale una proprietà, con il solo obiettivo di rivendere l’energia prodotta immettendola in rete.</p>
                                    </li>
                                    <li className="mb-2">
                                        <b>ATTIVITÀ CON BASSI CONSUMI DI ENERGIA</b>
                                        <p className="small">Il noleggio operativo è una soluzione ideale per impianti fotovoltaici che producono importanti quantità di energia.</p>
                                    </li>
                                    <li className="mb-2">
                                        <b>PRIVATI</b>
                                        <p className="small">Abitazioni private e piccoli complessi abitativi.</p>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Container className="py-5">
                <div className="py-5">
                    <Row className="mt-5">
                        <Col xs={12} lg={7} className="text-center text-lg-start d-flex justify-content-start align-items-center">
                            <div className="mb-5 mb-lg-0">
                                <h2 className="fw-bold mb-5">Come funziona?</h2>
                                <p className="mb-3">
                                    La locazione operativa o noleggio è una soluzione che consente, a fronte del pagamento di un <b>canone fisso periodico</b>, di avere la disponibilità di un <b>bene strumentale</b> all’esercizio della propria professione o attività imprenditoriale, senza acquisirne la proprietà.
                                </p>
                                <h5>
                                    Durante il periodo di locazione l’autoproduzione di energia porta da subito un risparmio nei consumi  che permette di coprire il costo della locazione autofinanziandosi il canone del noleggio.
                                </h5>
                            </div>
                        </Col>
                        <Col xs={12} lg={5} className="text-end d-flex justify-content-center align-items-center">
                            <img
                                src="https://i.ibb.co/TtCScqL/noleggio-2.png"
                                alt="Chi Siamo"
                                className="imgColResp imgBig"
                            />
                        </Col>
                    </Row>
                </div>
                <div className="py-5">
                    <Row className="mt-5">
                        <Col xs={12} lg={5} className="text-start d-flex justify-content-center align-items-center order-2 order-lg-1">
                            <img
                                src="https://i.ibb.co/zHhP5Wt/noleggio-3.png"
                                alt="Chi Siamo"
                                className="imgColResp imgBig"
                            />
                        </Col>
                        <Col xs={12} lg={7} className="text-center text-lg-end d-flex justify-content-start align-items-center order-1 order-lg-2">
                            <div className="mb-5 mb-lg-0">
                                <h2 className="fw-bold mb-5">Cosa comprende il canone?</h2>
                                <p className="mb-5">
                                    La durata del noleggio per la realizzazione di un impianto fotovoltaico varia da <b>5 a 8 anni</b> nel corso dei quali <b>la rata del noleggio sarà inferiore o pari al risparmio ottenuto</b> mediante la produzione ed autoconsumo di energia elettrica.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="py-5">
                    <Row className="mt-5">
                        <Col xs={12} lg={7} className="text-center text-lg-start d-flex justify-content-start align-items-center">
                            <div className="mb-5 mb-lg-0">
                                <h2 className="fw-bold mb-5">Perché conviene?</h2>
                                <ul>
                                    <li className="mb-2">
                                        <b>Mantenimento della liquidità</b>
                                        <p className="small">Non richiede la mobilitazione di fondi propri e non intacca gli indici di bilancio.</p>
                                    </li>
                                    <li className="mb-2">
                                        <b>Autonomia</b>
                                        <p className="small">Utilizzare energia verde proveniente da un impianto fotovoltaico è una scelta etica e sostenibile che attesta l’impegno aziendale nella salvaguardia dell’ambiente e rende autonomi dagli sbalzi della borsa del mercato elettrico.</p>
                                    </li>
                                    <li className="mb-2">
                                        <b>Niente centrale rischi, nessuna modifica nel rating creditizio</b>
                                        <p className="small">Il noleggio, come ogni altro contratto di affitto, non incide sullo stato patrimoniale, ma soltanto come voce di costo e pertanto non incide sul rating aziendale.</p>
                                    </li>
                                    <li className="mb-2">
                                        <b>Canoni costanti</b>
                                        <p className="small">Anziché sostenere elevati costi di acquisto e tutti gli oneri derivanti, le rate saranno concordate preventivamente e pari o inferiori al risparmio ottenuto, offrendo sicurezza di pianificazione grazie ai canoni.</p>
                                    </li>
                                    <li className="mb-2">
                                        <b>Deducibilità dei canoni nell’anno solare</b>
                                        <p className="small">Finanziariamente l’operazione consentirà approfittare i vantaggi fiscali legato alla deducibilità al 100% da IRES e IRAP del canone di noleggio dalle imposte, pariteticamente a quanto accade con i costi dell’energia elettrica, ma con il grande vantaggio di non versare denaro a fondo perduto.</p>
                                    </li>
                                    <li className="mb-2">
                                        <b>Prodotti di qualità con tecnologia sempre aggiornata</b>
                                        <p className="small">Il noleggio operativo garantisce la qualità dei prodotti utilizzati di ultima generazione ed una manutenzione h24 al fine di assicurare il massimo rendimento dell’impianto. Inoltre, essendo il fotovoltaico una tecnologia consolidata, a termine del noleggio si stima una ulteriore vita utile residua dell’impianto di circa 10-20 anni, in dipendenza della tipologia di contratto.</p>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col xs={12} lg={5} className="text-end d-flex justify-content-center align-items-center">
                            <img
                                src="https://i.ibb.co/GCKmn37/noleggio-4.jpg"
                                alt="Chi Siamo"
                                className="img4corners imgBig"
                            />
                        </Col>
                    </Row>
                </div>
                <div className="py-5">
                    <Row className="mt-5">
                        <Col xs={12} lg={5} className="text-start d-flex justify-content-center align-items-center order-2 order-lg-1">
                            <img
                                src="https://i.ibb.co/pnfRPb0/noleggio-5.jpg"
                                alt="Chi Siamo"
                                className="img4corners imgBig"
                            />
                        </Col>
                        <Col xs={12} lg={7} className="text-center text-lg-end d-flex justify-content-start align-items-center order-1 order-lg-2">
                            <div className="mb-5 mb-lg-0">
                                <h3 className="fw-bold mb-3">Alla fine del noleggio scegli tu cosa fare</h3>
                                <h5 className="mb-5">Cosa succede alla fine del periodo contrattuale?</h5>
                                <ul className="rtl">
                                    <li className="mb-2">
                                        <b>Acquisto</b>
                                        <p className="small">L’azienda può scegliere se acquistare l’impianto dal fornitore ad un prezzo preventivamente concordato ed utilizzarlo, godendo pienamente dei risparmi generati, fino a fine vita. In questo caso l’azienda potrà scegliere se sottoscrivere un contratto di manutenzione full service o un semplice servizio di manutenzione a chiamata senza nessun obbligo.</p>
                                    </li>
                                    <li className="mb-2">
                                        <b>Restituzione</b>
                                        <p className="small">L’azienda può restituire l’impianto ed il fornitore procederà alla rimozione senza ulteriori costi aggiuntivi.</p>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>

            <Footer />
        </div>
    )
}
