import {Navbar} from "../../Navbar/Navbar";
import {Col, Container, Row} from "react-bootstrap";
import {Footer} from "../../Footer/Footer";
import {MetaDecorator} from "../../utils/MetaDecorator";


export function SistemaAccumulo() {

    return (
        <div>
            <MetaDecorator
                title="Sistema di Accumulo"
                description="I sistemi di accumulo, conosciuti anche come accumulatori o sistemi di storage, sono delle batterie che, integrate all’impianto fotovoltaico, permettono di ottimizzare la produzione e i consumi elettrici di abitazioni e piccole industrie."
                keywords="sistema di accumulo, sistema accumulo, immagazzinare energia"
            />
            <Navbar />
            <Container fluid className="boxImgCover" style={{height:'60vh', backgroundImage:'url("https://i.ibb.co/frn5CvR/2.jpg")', backgroundSize:'cover', backgroundPosition:'center'}} />
            <Container className="py-5">
                <div className="text-center mb-0 mb-lg-5 pt-3">
                    <h1 className="fw-bold">Sistema Accumulo</h1>
                </div>
                <div className="py-5">
                    <Row className="mt-5">
                        <Col xs={12} lg={5} className="text-start d-flex justify-content-center align-items-center order-2 order-lg-1">
                            <img
                                src="https://i.ibb.co/XstG7vk/sistemaaccumulo3.jpg"
                                alt="Chi Siamo"
                                className="img4corners imgBig"
                            />
                        </Col>
                        <Col xs={12} lg={7} className="text-center text-lg-end d-flex justify-content-start align-items-center order-1 order-lg-2">
                            <div className="mb-5 mb-lg-0">
                                <h2 className="fw-bold mb-5">Raggiungi l'indipendenza</h2>
                                <p className="mb-5">
                                    <p>I sistemi di accumulo, conosciuti anche come accumulatori o sistemi di storage, sono delle batterie che, integrate all’impianto fotovoltaico, permettono di ottimizzare la produzione e i consumi elettrici di abitazioni e piccole industrie.</p>
                                    <br />
                                    <p>La funzione principale di questi accumulatori domestici è quella di immagazzinare l’energia elettrica in eccesso, che in loro assenza verrebbe immessa nella rete elettrica nazionale, e renderla disponibile nei momenti di bisogno.</p>
                                </p>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="py-5">
                    <Row className="mt-5">
                        <Col xs={12} lg={7} className="text-center text-lg-start d-flex justify-content-start align-items-center">
                            <div className="mb-5 mb-lg-0">
                                <h2 className="fw-bold mb-5">Il funzionamento durante la giornata</h2>
                                <br />
                                <ul>
                                    <li>Al mattino l'energia prodotta viene consumata direttamente, mentre quella in eccesso viene immagazzinata all'interno delle batterie.</li>
                                    <li>Nel pomeriggio, quando l'accumulatore è carico, il surplus di energia viene immesso nella rete elettrica.</li>
                                    <li>Di sera l'energia accumulata nella batteria viene utilizzata all'interno della propria abitazione.</li>
                                    <li>Di notte se l'accumulatore non fosse sufficiente a soddisfare l'intero fabbisogno energetico, l'energia viene prelevata dalla rete.</li>
                                </ul>
                            </div>
                        </Col>
                        <Col xs={12} lg={5} className="text-end d-flex justify-content-center align-items-center">
                            <img
                                src="https://i.ibb.co/JdPkd3W/sistemaaccumulo-2.jpg"
                                alt="Chi Siamo"
                                className="img4corners imgBig"
                            />
                        </Col>
                    </Row>
                </div>
                <div className="py-5">
                    <Row className="mt-5">
                        <Col xs={12} lg={5} className="text-start d-flex justify-content-center align-items-center order-2 order-lg-1">
                            <img
                                src="https://i.ibb.co/kHdG7VL/sistemaaccumulo1.jpg"
                                alt="Chi Siamo"
                                className="img4corners imgBig"
                            />
                        </Col>
                        <Col xs={12} lg={7} className="text-center text-lg-end d-flex justify-content-start align-items-center order-1 order-lg-2">
                            <div className="mb-5 mb-lg-0">
                                <h2 className="fw-bold mb-5">Perché scegliere l'accumulo</h2>
                                <p className="mb-5">
                                    <p>Rispetto a un impianto fotovoltaico senza batteria, un sistema fotovoltaico con accumulo ha un prezzo nettamente superiore. È bene però precisare che nonostante la spesa iniziale sia elevata, i vantaggi di questi sistemi fotovoltaici sono maggiori rispetto a un semplice impianto solare permettendo di raggiungere un’autonomia energetica <b>quasi del 100%</b>.</p>
                                    <br />
                                    <p>Inoltre, quando si considera il prezzo totale di un impianto fotovoltaico con accumulo, bisogna tenere in considerazione le <b>agevolazioni statali</b> e le <b>detrazioni fiscali</b>, quali <b>Superbonus 110</b> e <b>bonus ristrutturazioni</b>, che lo Stato italiano offre per gli interventi di ristrutturazione e installazione di pannelli fotovoltaici.</p>
                                </p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>

            <Footer />
        </div>
    )
}
