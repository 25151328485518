import {Navbar} from "../Navbar/Navbar";
import {Button, Col, Container, Row} from "react-bootstrap";
import ImgFrameVerde from "../imgs/frame_green.png";
import {
    FaChartBar,
    FaDollarSign,
    FaGlobeAmericas,
    FaHammer,
    FaRuler, FaScroll,
    FaSolarPanel,
    FaStar,
    FaStickyNote
} from "react-icons/fa";
import {Footer} from "../Footer/Footer";
import {MetaDecorator} from "../utils/MetaDecorator";


export function Privacy() {

    return (
        <div>
            <MetaDecorator
                title="Privacy"
            />
            <Navbar />
            <div className="py-5 d-flex justify-content-center align-items-center" style={{minHeight:'50vh'}}>
                <Container className="py-5">
                    <Container className="py-5">
                        <div>
                            <h1 className="fw-bold text-lg-start mb-5">Privacy Policy</h1>
                            <div className="privacy">
                                <div><h6>Relativa ai trattamenti effettuati tramite il sito incluso i cookie</h6><p>Per
                                    noi la protezione dei dati è un argomento molto serio per cui desideriamo informarLa
                                    in merito alla modalità con la quale i dati vengono trattati e ai diritti che Lei
                                    può esercitare ai sensi delle attinenti normative sulla protezione dei dati,
                                    Regolamento UE 2016/679 (di seguito anche: “GDPR”).</p></div>
                                <div><h6>1. Titolare del trattamento e DPO</h6><p><b>Titolare del
                                    Trattamento</b><br/><b>MGM Solar s.r.l.</b><br/>S.S. 28 Sud, 14<br/>12084 Mondovì (CN)<br/><a href="mailto:privacy@mgmsolar.it">privacy@mgmsolar.it</a>
                                </p></div>
                                <div><h6>2. Le categorie di dati che vengono sottoposti a trattamento</h6><p>I dati
                                    trattati dal Titolare sono “dati personali” (ex. Art. 4.1 del GDPR) da Lei
                                    conferiti. In particolare, le categorie di dati personali attinenti possono essere,
                                    a mero titolo esemplificativo ma certo non esaustivo:</p>
                                    <ul>
                                        <li>Eventuali dati anagrafici e identificativi (a mero titolo esemplificativo e
                                            non esaustivo, eventuale nome e cognome, ecc.);
                                        </li>
                                        <li>Eventuali dati di contatto (indirizzo IP, indirizzo e-mail, e dati similari)
                                            e dati di navigazione.
                                        </li>
                                    </ul>
                                </div>
                                <div><h6>Trattamento dei dati utili ai fini della navigazione</h6><p>I sistemi
                                    informatici e le procedure software preposte al funzionamento di questo sito web
                                    acquisiscono, nel corso del loro normale esercizio, alcuni dati personali la cui
                                    trasmissione è implicita nell’uso dei protocolli di comunicazione di Internet.</p>
                                    <p>Si tratta di informazioni che non sono raccolte per essere associate a
                                        interessati identificati, ma che per loro stessa natura potrebbero, attraverso
                                        elaborazioni ed associazioni con dati detenuti da terzi, permettere di
                                        identificare gli utenti.</p><p>Tra le informazioni che possono essere raccolte
                                        abbiamo gli indirizzi IP, il tipo di browser o il sistema operativo utilizzato,
                                        gli indirizzi in notazione URI (Uniform Resource Identifier), il nome di dominio
                                        e gli indirizzi dei siti Web dai quali è stato effettuato l'accesso o l’uscita
                                        (referring/exit pages), l’orario in cui è stata fatta la richiesta al server, il
                                        metodo utilizzato e informazioni sulla risposta ottenuta, ulteriori informazioni
                                        sulla navigazione dell’utente nel sito (si veda anche la sezione relativa ai
                                        cookie) ed altri parametri relativi al sistema operativo e all’ambiente
                                        informatico dell’utente.</p><p>Questi stessi dati potrebbero, essere altresì
                                        utilizzati per individuare ed accertare le responsabilità in caso di eventuali
                                        reati informatici ai danni del sito.</p></div>
                                <div><h6>Utilizzo dei cookie</h6><p>Quando si visita qualsiasi sito Web, questo può
                                    memorizzare o recuperare informazioni sul tuo browser, in gran parte sotto forma di
                                    cookie. Queste informazioni potrebbero riguardare te, le tue preferenze o il tuo
                                    dispositivo e sono utilizzate in gran parte per far funzionare il sito anche secondo
                                    le tue aspettative. Le informazioni di solito non ti identificano direttamente, ma
                                    possono fornire un'esperienza Web più personalizzata. Poiché rispettiamo il tuo
                                    diritto alla protezione dei tuoi dati personali, è possibile scegliere di non
                                    consentire alcuni tipo di cookie. Clicca su "Impostazioni dei Cookie" e sulle
                                    intestazioni delle diverse categorie di cookie per saperne di più e modificare le
                                    impostazioni predefinite. Tuttavia, l’inibizione di alcuni tipi di cookie può avere
                                    conseguenze sulla tua navigazione sul sito e sull’utilizzo dei relativi servizi che
                                    siamo in grado di offrire.</p><p><a
                                    href="https://cookiepedia.co.uk/giving-consent-to-cookies" target="_blank"
                                    rel="noreferrer">Ulteriori informazioni</a></p><p>Le seguenti informazioni sono rese
                                    all’utente in attuazione del provvedimento del Garante per la protezione dei dati
                                    personali dell'8 maggio 2014 "Individuazione delle modalità semplificate per
                                    l'informativa e l'acquisizione del consenso per l'uso dei cookie".</p></div>
                                <div><h6>Che cosa sono i Cookie? E quali Cookie utilizziamo?</h6><p>I cookie sono
                                    stringhe di testo di piccola dimensione che un sito web può inviare, durante la
                                    navigazione, al tuo dispositivo (sia esso un pc, un notebook, uno smartphone, un
                                    tablet; di norma sono conservati direttamente sul browser utilizzato per la
                                    navigazione). Lo stesso sito web che li ha trasmessi, poi può leggere e registrare i
                                    cookie che si trovano sullo stesso dispositivo per ottenere informazioni di vario
                                    tipo. Quali? Per ogni tipo di cookie c'è un ruolo ben definito.</p></div>
                                <div><h6>Quanti tipi di cookie esistono?</h6><p>Esistono due macro-categorie
                                    fondamentali, con caratteristiche diverse: cookie tecnici e cookie di
                                    profilazione.</p><p>I cookie tecnici sono generalmente necessari per il corretto
                                    funzionamento del sito web e per permettere la navigazione; senza di essi potresti
                                    non essere in grado di visualizzare correttamente le pagine oppure di utilizzare
                                    alcuni servizi. Per esempio, un cookie tecnico è indispensabile per mantenere
                                    l'utente collegato durante tutta la visita a un sito web, oppure per memorizzare le
                                    impostazioni della lingua, della visualizzazione, e così via.</p><p>I cookie tecnici
                                    utilizzati in questo sito possono essere ulteriormente distinti in:</p>
                                    <ul>
                                        <li><b>Cookie necessari</b><br/>Questi cookie sono necessari per il
                                            funzionamento del sito, con particolare riferimento alla normale navigazione
                                            e alla fruizione dello stesso, e non possono essere disattivati nei nostri
                                            sistemi. Di solito vengono impostati solo in risposta alle azioni da te
                                            effettuate che costituiscono una richiesta di servizi, come l'impostazione
                                            delle preferenze di privacy, l'accesso o la compilazione di moduli. È
                                            possibile impostare il browser per bloccare questi cookie, ma di conseguenza
                                            alcune o tutte le parti del sito non funzioneranno. Questi cookie non
                                            memorizzano informazioni personali.
                                        </li>
                                        <li><b>Cookie analitici</b><br/>Questi cookie ci permettono di contare le visite
                                            e le fonti di traffico in modo da poter valutare e migliorare le prestazioni
                                            del nostro sito. Ci aiutano a sapere quali sono le pagine più e meno
                                            popolari e vedere come i visitatori navigano sul sito. Tutte le informazioni
                                            raccolte dai cookie sono aggregate e quindi anonime. Se non autorizzi questi
                                            cookie, non potremo valutare la navigazione e il comportamento collettivo
                                            della nostra utenza e migliorare di conseguenza le prestazioni del sito.
                                        </li>
                                        <li><b>Cookie di funzionalità</b><br/>Questi cookie consentono al sito di
                                            fornire funzionalità e personalizzazione avanzate, che permettono la
                                            navigazione in funzione di una serie di criteri selezionati (ad esempio, la
                                            lingua, i prodotti selezionati per gli acquisti) al fine di migliorare il
                                            servizio reso. Possono essere impostati da noi o da provider di terze parti
                                            i cui servizi sono stati aggiunto alle nostre pagine. Se non si
                                            autorizzassero questi cookie, alcuni o tutti la totalità di questi servizi
                                            potrebbero non funzionare correttamente.
                                        </li>
                                    </ul>
                                    <p>I cookie di profilazione hanno il compito di profilare l'utente e vengono
                                        utilizzati al fine di inviare messaggi pubblicitari in linea con le preferenze
                                        manifestate dallo stesso durante la sua navigazione</p>
                                    <ul>
                                        <li><b>Cookie di profilazione</b><br/>I cookie di profilazione possono essere
                                            utilizzati, anche da terze aziende, per costruire un profilo dei tuoi
                                            interessi e mostrarti o inviarti annunci pertinenti su questo e altri siti.
                                        </li>
                                    </ul>
                                    <p>Questi cookie possono essere impostati, tramite il nostro sito, solamente dai
                                        nostri partner pubblicitari e quindi sono cookie di terze parti. Tali cookie
                                        sono basati unicamente sull'individuazione del tuo browser e del tuo dispositivo
                                        internet. Se non si autorizzano questi cookie, non ti verranno mostrati o
                                        inviati i suddetti annunci, né verranno creati profili dei tuoi interessi. Il
                                        rifiuto o la revoca del consenso all’utilizzo di uno o diversi cookie di
                                        profilazione non interferirà sulla possibilità di accedere al Sito o di navigare
                                        sullo stesso.</p><p>Qualora sia iscritto a un nostro programma di fidelizzazione
                                        accettando tali cookie e navigando in seguito a ricezione di lettura di nostra
                                        email mirata o di accesso ad area riservata, tali cookie e le relative analisi
                                        verranno abbinati ai suoi dati di registrazione ai programmi o ai suoi
                                        riferimenti di socio, tramite un codice numerico generato automaticamente.</p>
                                    <p>L’utilizzo di tali cookie necessita dell’acquisizione preventiva del libero
                                        consenso dell’utente che il Sito acquisisce in coerenza con il Provvedimento del
                                        Garante dell’8 maggio del 2014 e ss. mm. ii.</p><p>I cookie possono, ancora,
                                        essere classificati come:</p>
                                    <ul>
                                        <li><b>cookie di prima parte</b> (first-part cookie) ossia cookie generati e
                                            gestiti direttamente dal soggetto gestore del sito web sul quale l'utente
                                            sta navigando.
                                        </li>
                                        <li><b>cookie di terza parte</b> (third-part cookie), i quali sono generati e
                                            gestiti da soggetti diversi dal gestore del sito web sul quale l'utente sta
                                            navigando (in forza, di regola, di un contratto tra il titolare del sito web
                                            e la terza parte).
                                        </li>
                                    </ul>
                                </div>
                                <div><h6>Durata dei cookie</h6><p>I cookie hanno una durata dettata dalla data di
                                    scadenza (o da un'azione specifica come la chiusura del browser) impostata al
                                    momento dell'installazione.</p><p>I cookie possono essere:</p>
                                    <ul>
                                        <li><b>temporanei o di sessione</b> (session cookie): sono utilizzati per
                                            archiviare informazioni temporanee, consentono di collegare le azioni
                                            eseguite durante una sessione specifica e vengono rimossi dal computer alla
                                            chiusura del browser;
                                        </li>
                                        <li><b>permanenti</b> (persistent cookie): sono utilizzati per archiviare
                                            informazioni, ad esempio il nome e la password di accesso, in modo da
                                            evitare che l'utente debba digitarli nuovamente ogni volta che visita un
                                            sito specifico. Questi rimangono memorizzati nel computer anche dopo aver
                                            chiuso il browser. Per la durata specifica dei singoli cookie si rimanda
                                            alle pannello delle impostazioni degli stessi “Impostazioni dei Cookie”.
                                        </li>
                                    </ul>
                                </div>
                                <div><h6>Gestione dei cookie</h6><p>In riferimento al provvedimento di Individuazione
                                    delle modalità semplificate per l'informativa e l'acquisizione del consenso per
                                    l'uso dei cookie - 8 maggio 2014 (Pubblicato sulla Gazzetta Ufficiale n. 126 del 3
                                    giugno 2014), puoi modificare in qualsiasi momento il consenso ai cookie cliccando
                                    su link "Impostazione dei Cookie" presente nella parte inferiore di ogni pagina del
                                    presente Sito Web.</p><p>MGM Solar s.r.l. terrà opportuna traccia del/dei consenso/i
                                    dell’Utente attraverso un apposito cookie tecnico, considerato dal Garante Privacy
                                    uno strumento “non particolarmente invasivo”. L’Utente può negare il suo consenso
                                    e/o modificare in ogni momento le proprie opzioni relative all’uso dei cookie da
                                    parte del presente Sito Web, tramite accesso alla presente Informativa Privacy che è
                                    “linkabile” da ogni pagina del presente Sito.</p><p>Se è già stato dato il consenso
                                    ma si vogliono cambiare le autorizzazioni dei cookie, bisogna cancellarli attraverso
                                    il browser, come indicato sotto, perché altrimenti quelli già installati non
                                    verranno rimossi. In particolare, si tenga presente che non è possibile in alcun
                                    modo controllare i cookie di terze parti, quindi se è già stato dato precedentemente
                                    il consenso, è necessario procedere alla cancellazione dei cookie attraverso il
                                    browser (sezione dedicata) oppure chiedendo l'opt-out direttamente alle terze parti
                                    o tramite il sito: <a href="https://www.youronlinechoices.com/it/le-tue-scelte"
                                                          target="_blank"
                                                          rel="noreferrer">https://www.youronlinechoices.com/it/le-tue-scelte</a>
                                </p><p>Se vuoi saperne di più, puoi consultare i seguenti siti:</p>
                                    <ul>
                                        <li><a href="https://www.youronlinechoices.com/" target="_blank"
                                               rel="noreferrer">youronlinechoices.com</a></li>
                                        <li><a href="https://www.allaboutcookies.org/verify" target="_blank"
                                               rel="noreferrer">allaboutcookies.org/verify</a></li>
                                        <li><a href="https://www.cookiechoices.org/" target="_blank"
                                               rel="noreferrer">cookiechoices.org</a></li>
                                        <li><a
                                            href="https://www.garanteprivacy.it/web/guest/home/docweb/-/docweb-%20display/docweb/3118884"
                                            target="_blank" rel="noreferrer">garanteprivacy.it</a></li>
                                    </ul>
                                </div>
                                <div><h6>Come disabilitare/cancellare i cookie mediante configurazione del browser</h6>
                                </div>
                                <div><h6>Chrome</h6>
                                    <ol>
                                        <li>Eseguire il browser Chrome</li>
                                        <li>Fare click sul menù presente nella barra degli strumenti del browser a
                                            fianco della finestra di inserimento url per la navigazione
                                        </li>
                                        <li>Selezionare <b>Impostazioni</b></li>
                                        <li>Fare clic su <b>Mostra Impostazioni Avanzate</b></li>
                                        <li>Nella sezione "Privacy" fare clic su bottone <b>Impostazioni contenuti</b>
                                        </li>
                                        <li>Nella sezione "Cookie" è possibile modificare le seguenti impostazioni
                                            relative ai cookie:
                                            <ul>
                                                <li>Consentire il salvataggio dei dati in locale</li>
                                                <li>Modificare i dati locali solo fino alla chiusura del browser</li>
                                                <li>Impedire ai siti di impostare i cookie</li>
                                                <li>Bloccare i cookie di terze parti e i dati dei siti</li>
                                                <li>Gestire le eccezioni per alcuni siti internet</li>
                                                <li>Eliminare uno o tutti i cookie</li>
                                            </ul>
                                        </li>
                                    </ol>
                                    <p>Per maggiori informazioni visita la <a
                                        href="https://support.google.com/accounts/answer/61416?hl=en" target="_blank"
                                        rel="noreferrer">pagina dedicata</a>.</p></div>
                                <div><h6>Mozilla Firefox</h6>
                                    <ol>
                                        <li>Eseguire il browser Mozilla Firefox</li>
                                        <li>Fare click sul menù presente nella barra degli strumenti del browser a
                                            fianco della finestra di inserimento url per la navigazione
                                        </li>
                                        <li>Selezionare <b>Opzioni</b></li>
                                        <li>Selezionare il pannello <b>Privacy</b></li>
                                        <li>Fare clic su <b>Mostra Impostazioni Avanzate</b></li>
                                        <li>Nella sezione "Privacy" fare clic su bottone <b>Impostazioni contenuti</b>
                                        </li>
                                        <li>Nella sezione <b>Tracciamento</b> è possibile modificare le seguenti
                                            impostazioni relative ai cookie:
                                            <ul>
                                                <li>Richiedi ai siti di non effettuare alcun tracciamento</li>
                                                <li>Comunica ai siti la disponibilità ad essere tracciato</li>
                                                <li>Non comunicare alcuna preferenza relativa al tracciamento dei dati
                                                    personali
                                                </li>
                                            </ul>
                                        </li>
                                        <li>Dalla sezione <b>Cronologia</b> è possibile:
                                            <ul>
                                                <li>Abilitando "Utilizza impostazioni personalizzate" selezionare di
                                                    accettare i cookie di terze parti (sempre, dai siti più visitato o
                                                    mai) e di conservarli per un periodo determinato (fino alla loro
                                                    scadenza, alla chiusura di Firefox o di chiedere ogni volta)
                                                </li>
                                                <li>Rimuovere i singoli cookie immagazzinati.</li>
                                            </ul>
                                        </li>
                                    </ol>
                                    <p>Per maggiori informazioni visita la <a
                                        href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences"
                                        target="_blank" rel="noreferrer">pagina dedicata</a>.</p></div>
                                <div><h6>Microsoft Edge</h6>
                                    <ol>
                                        <li>Eseguire il browser Edge</li>
                                        <li>Fare click sul pulsante <b>Strumenti</b> e scegliere <b>Impostazioni</b>
                                        </li>
                                        <li>Fare click sulla scheda <b>Cookie e autorizzazioni sito</b> e, nella
                                            sezione <b>Cookie e dati archiviati</b>, modificare la scelta in funzione
                                            dell’azione desiderata per i cookie:
                                            <ul>
                                                <li>Bloccare i cookie</li>
                                                <li>Consentire i cookie</li>
                                                <li>Selezionare i siti da cui ottenere o bloccare i cookie.</li>
                                            </ul>
                                        </li>
                                    </ol>
                                    <p>Per maggiori informazioni visita la <a
                                        href="https://support.microsoft.com/it-it/windows/eliminare-e-gestire-i-cookie-168dab11-0753-043d-7c16-ede5947fc64d"
                                        target="_blank" rel="noreferrer">pagina dedicata</a>.</p></div>
                                <div><h6>Microsoft Internet Explorer</h6>
                                    <ol>
                                        <li>Eseguire il browser Internet Explorer</li>
                                        <li>Fare click sul pulsante <b>Strumenti</b> e scegliere <b>Opzioni Internet</b>
                                        </li>
                                        <li>Fare click sulla scheda <b>Privacy</b> e, nella sezione <b>Impostazioni</b>,
                                            modificare il dispositivo di scorrimento in funzione dell’azione desiderata
                                            per i cookie:
                                            <ul>
                                                <li>Bloccare tutti i cookie</li>
                                                <li>Consentire tutti i cookie</li>
                                                <li>Selezionare i siti da cui ottenere cookie: spostare il cursore in
                                                    una posizione intermedia in modo da non bloccare o consentire tutti
                                                    i cookie, premere quindi su Siti, nella casella Indirizzo Sito Web
                                                    inserire un sito internet e quindi premere su Blocca o Consenti.
                                                </li>
                                            </ul>
                                        </li>
                                    </ol>
                                    <p>Per maggiori informazioni visita la <a
                                        href="https://windows.microsoft.com/it-it/windows-vista/block-or-allow-cookies"
                                        target="_blank" rel="noreferrer">pagina dedicata</a>.</p></div>
                                <div><h6>Apple Safari</h6>
                                    <ol>
                                        <li>Eseguire il browser Apple Safari</li>
                                        <li>Fare click su <b>Safari</b>, selezionare <b>Preferenze</b> e premere
                                            su <b>Privacy</b></li>
                                        <li>Nella sezione Blocca Cookie specificare come Safari deve accettare i cookie
                                            dai siti internet.
                                        </li>
                                        <li>Per visionare quali siti hanno immagazzinato i cookie cliccare
                                            su <b>Dettagli</b></li>
                                    </ol>
                                    <p>Per maggiori informazioni visita la <a
                                        href="https://support.apple.com/it-it/guide/safari/sfri47acf5d6/mac"
                                        target="_blank" rel="noreferrer">pagina dedicata</a>.</p></div>
                                <div><h6>Apple Safari iOS (dispositivi mobile)</h6>
                                    <ol>
                                        <li>Eseguire il browser Apple Safari iOS</li>
                                        <li>Tocca su <b>Impostazioni</b> e poi <b>Safari</b></li>
                                        <li>Tocca su <b>Blocca Cookie</b> e scegli tra le varie opzioni: "Mai", "Di
                                            terze parti e inserzionisti" o "Sempre"
                                        </li>
                                        <li>Per cancellare tutti i cookie immagazzinati da Safari, tocca
                                            su <b>Impostazioni</b>, poi su <b>Safari</b> e infine su <b>Cancella Cookie
                                                e dati</b></li>
                                    </ol>
                                    <p>Per maggiori informazioni visita la <a
                                        href="https://support.apple.com/en-us/HT201265" target="_blank"
                                        rel="noreferrer">pagina dedicata</a>.</p></div>
                                <div><h6>Opera</h6>
                                    <ol>
                                        <li>Eseguire il browser Opera</li>
                                        <li>Fare click sul <b>Preferenze</b> poi su <b>Avanzate</b> e infine
                                            su <b>Cookie</b></li>
                                        <li>Selezionare una delle seguenti opzioni:
                                            <ul>
                                                <li>Accetta tutti i cookie</li>
                                                <li>Accetta i cookie solo dal sito che si visita: i cookie di terze
                                                    parti e quelli che vengono inviati da un dominio diverso da quello
                                                    che si sta visitando verranno rifiutati
                                                </li>
                                                <li>Non accettare mai i cookie: tutti i cookie non verranno mai
                                                    salvati.
                                                </li>
                                            </ul>
                                        </li>
                                    </ol>
                                    <p>Per maggiori informazioni visita la <a
                                        href="https://www.opera.com/help/tutorials/security/privacy/" target="_blank"
                                        rel="noreferrer">pagina dedicata</a>.</p></div>
                                <div><h6>Avviso riguardante i minori di 14 anni</h6><p>I minori di 14 anni non possono
                                    fornire dati personali. MGM Solar s.r.l. non sarà in alcun modo responsabile di
                                    eventuali raccolte di dati personali, nonché di dichiarazioni mendaci, fornite dal
                                    minore, e in ogni caso, qualora se ne ravvisasse l’utilizzo, MGM Solar s.r.l. agevolerà
                                    il diritto di accesso e di cancellazione inoltrato da parte del tutore legale o da
                                    chi ne esercita la responsabilità genitoriale.</p></div>
                                <div><h6>Esercizio dei diritti dell’interessato</h6><p>L’interessato, in relazione ai
                                    dati personali oggetto della presente informativa, ha la facoltà di esercitare i
                                    diritti previsti dal Regolamento UE di seguito riportati:</p>
                                    <ul>
                                        <li><b>Diritto di accesso dell’interessato [art. 15 del Regolamento UE]</b>:
                                            l’interessato ha diritto di ottenere dal titolare conferma che sia o meno in
                                            corso un trattamento dei propri dati personali e, in tal caso, l’accesso
                                            alle informazioni espressamente previste dall’articolo citato, tra cui a
                                            titolo esemplificativo e non esaustivo le finalità del trattamento, le
                                            categorie di dati e destinatari, il periodo di conservazione, l’esistenza
                                            del diritto di cancellazione, rettifica o limitazione, il diritto di
                                            proporre reclamo, tutte le informazioni disponibili sull’origine dei dati,
                                            l’eventuale esistenza di un processo decisionale automatizzato ai sensi
                                            dell’art. 22 del Regolamento, nonché copia dei propri dati personali.
                                        </li>
                                        <li><b>Diritto di rettifica [art. 16 del Regolamento UE]</b>: l’interessato ha
                                            diritto di ottenere dal titolare la rettifica e/o l’integrazione dei dati
                                            personali inesatti che lo riguardano, senza ingiustificato ritardo;
                                        </li>
                                        <li><b>Diritto alla cancellazione (“diritto all’oblio”) [art. 17 del Regolamento
                                            UE]</b>: l’interessato ha diritto alla cancellazione dei propri dati
                                            personali senza ingiustificato ritardo, se sussiste uno dei motivi
                                            espressamente previsti dall’articolo citato, tra cui a titolo
                                            esemplificativo e non esaustivo il venir meno della necessità del
                                            trattamento rispetto alle finalità, la revoca del consenso su cui si basa il
                                            trattamento, opposizione al trattamento nel caso in cui sia basato su
                                            interesse legittimo non prevalente, trattamento illecito dei dati,
                                            cancellazione per obblighi di legge, dati dei minori trattati in assenza
                                            delle condizioni di applicabilità previsto dall’art. 8 del Regolamento;
                                        </li>
                                        <li><b>Diritto di limitazione di trattamento [art. 18 del Regolamento UE]</b>:
                                            nei casi previsti dall’art. 18, tra cui il trattamento illecito, la
                                            contestazione dell’esattezza dei dati, l’opposizione dell’interessato e il
                                            venir meno del bisogno trattamento da parte del titolare, i dati
                                            dell’interessato devono essere trattati solo per la conservazione salvo il
                                            consenso dello stesso e gli altri casi previsti espressamente dall’articolo
                                            citato;
                                        </li>
                                        <li><b>Diritto alla portabilità dei dati [art. 20 del Regolamento UE]</b>:
                                            l’interessato, nei casi in cui il trattamento si basi sul consenso e sul
                                            contratto e sia effettuato con mezzi automatizzati, potrà richiedere di
                                            ricevere i propri dati personali in formato strutturato, di uso comune e
                                            leggibile da dispositivo automatico, e ha diritto di trasmetterli a un altro
                                            titolare;
                                        </li>
                                        <li><b>Diritto di opposizione [art. 21 del Regolamento UE]</b>: l’interessato ha
                                            diritto di opporsi al trattamento dei propri dati personali, nel caso in cui
                                            il trattamento sia basato su interesse legittimo non prevalente o venga
                                            effettuato per finalità di marketing diretto;
                                        </li>
                                        <li><b>Diritto di non essere sottoposto a processi decisionale automatizzato
                                            [art. 22 del Regolamento UE]</b>: l’interessato ha diritto a non essere
                                            sottoposto ad una decisione, compresa la profilazione, basata unicamente sul
                                            trattamento automatizzato (ad esempio svolta esclusivamente tramite
                                            strumenti elettronici o programmi informatici).
                                        </li>
                                    </ul>
                                    <p>La descrizione sopra riportata non sostituisce il testo degli articoli ivi citati
                                        che qui si richiamano integralmente e alla cui lettura completa si rinvia: <a
                                            href="https://www.garanteprivacy.it/regolamentoue/diritti-degli-interessati"
                                            target="_blank"
                                            rel="noreferrer">https://www.garanteprivacy.it/regolamentoue/diritti-degli-interessati</a>
                                    </p></div>
                                <div><h6>Diritto di proporre reclamo</h6><p>L’interessato, qualora ritenga che i propri
                                    diritti siano stati compromessi, ha diritto di proporre reclamo all’Autorità Garante
                                    per la protezione dei dati personali, secondo le modalità indicate dalla stessa
                                    Autorità al seguente indirizzo internet :</p><p><a
                                    href="https://www.garanteprivacy.it/web/guest/home/docweb/-/docweb-display/docweb/4535524"
                                    target="_blank" rel="noreferrer">www.garanteprivacy.it</a> oppure inviando
                                    comunicazione scritta all’Autorità Garante per la Protezione dei Dati Personali,
                                    Piazza Montecitorio n.121, 00186 Roma. Per maggiori approfondimenti sui diritti
                                    degli interessati previsti dal Garante si rimanda al <a
                                        href="https://www.garanteprivacy.it/web/guest/regolamentoue/diritti-degli-interessati"
                                        target="_blank" rel="noreferrer">link</a></p></div>
                                <div className="fst-italic"><p>Informativa Privacy ai sensi dell’art. 13 del regolamento
                                    ue 2016/679 relative ai trattamenti effettuati tramite il sito incluso i cookie.</p>
                                    <p>Aggiornamento del 21 settembre 2024</p></div>
                            </div>
                        </div>
                    </Container>
                </Container>
            </div>

            <Footer/>
        </div>
    )
}
