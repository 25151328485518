import 'bootstrap/dist/css/bootstrap.min.css';
import './mainstyle.css';
import firebase from 'firebase/app'
import 'firebase/analytics'
import {Router} from "./Router";


if(!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: "AIzaSyCju9F7Kd49z9gT_s8LML63EPybrKaGmeE",
    authDomain: "mgmsolar-36fc8.firebaseapp.com",
    projectId: "mgmsolar-36fc8",
    storageBucket: "mgmsolar-36fc8.appspot.com",
    messagingSenderId: "1055109713628",
    appId: "1:1055109713628:web:a7b066f10450a08bc3ee19",
    measurementId: "G-H3KYXNCY1C"
  })
}
firebase.analytics()

function App() {
    return (
        <div>
            <Router />
        </div>
    );
}

export default App;
