import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import {IconPicker, IconPickerItem} from "react-fa-icon-picker";
import {useEffect, useState} from "react";
import firebase from "firebase";
import {FaPencilAlt, FaTimes} from "react-icons/fa";
import {nanoid} from "nanoid";


export function ManageRealizzazioni() {

    const [dataDwl, setDataDwl] = useState([])
    useEffect(() => {
        firebase.firestore().collection('realizzazioni').onSnapshot((snapshot) => {
            let res = []
            snapshot.forEach((doc) => res.push(doc.data()))
            setDataDwl(res)
        })
    }, [])

    const [modaledit, setModaledit] = useState()
    const [input1, setInput1] = useState({
        icon: '',
        label: '',
        desc: '',
    })
    const [input2, setInput2] = useState({
        label: '',
        desc: '',
    })
    const [input3, setInput3] = useState('')

    return (
        <div>

            <div className="mb-5">
                <h3 className="fw-bold mb-3 text-center text-lg-start">Realizzazioni</h3>
                <Row>
                    {
                        dataDwl!==[] ?
                            dataDwl.map(a => cardRealizzazione(a))
                            :
                            null
                    }
                </Row>
            </div>


            {
                modaledit ?
                    <Modal show={modaledit} onHide={() => setModaledit()}
                           size="xl"
                           aria-labelledby="contained-modal-title-vcenter"
                           centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Modifica {modaledit.titolo}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                            <div className="mb-4">
                                <h5 className="fw-bold mb-2">Informazioni</h5>
                                <Form.Group className="mb-2">
                                    <Form.Label className="small m-1">Titolo *</Form.Label>
                                    <Form.Control
                                        type="text"
                                        className="rounded10"
                                        placeholder="Titolo realizzazione"
                                        value={modaledit.titolo}
                                        onChange={e => setModaledit({
                                            ...modaledit,
                                            titolo: e.target.value,
                                        })}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-2">
                                    <Form.Label className="small m-1">Foto cover *</Form.Label>
                                    <Form.Control
                                        type="link"
                                        className="rounded10"
                                        placeholder="Link foto principale"
                                        value={modaledit.fotoCover}
                                        onChange={e => setModaledit({
                                            ...modaledit,
                                            fotoCover: e.target.value,
                                        })}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-2">
                                    <Form.Label className="small m-1">Testo *</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={4}
                                        className="rounded10"
                                        placeholder="Testo descrittivo"
                                        value={modaledit.testo}
                                        onChange={e => setModaledit({
                                            ...modaledit,
                                            testo: e.target.value,
                                        })}
                                    />
                                </Form.Group>
                            </div>

                            <div className="mb-4">
                                <h5 className="fw-bold mb-2">Dati generali</h5>

                                <ul>
                                    {
                                        modaledit.datigenerali.length!==0 ?
                                            modaledit.datigenerali.map(a => <li className="d-flex justify-content-start align-items-center">
                                                <IconPickerItem icon={a.icon} size={18} /> <span> &nbsp;&nbsp;{a.label}: <b>{a.desc}</b></span>
                                                <span>&nbsp;&nbsp;<FaTimes
                                                    className="iconHover"
                                                    size="1.2em"
                                                    color="#ff0000"
                                                    onClick={() => {
                                                        if(window.confirm('Eliminare questa voce?')) {
                                                            setModaledit({
                                                                ...modaledit,
                                                                datigenerali: modaledit.datigenerali.filter(x => x.icon!==a.icon && x.label!==a.label && x.desc!==a.desc)
                                                            })
                                                        }
                                                    }}
                                                /></span>
                                            </li>)
                                            :
                                            <li>
                                                <i className="fw-bold opacity-50 text-decoration-underline">Nessun dato aggiunto</i>
                                            </li>
                                    }
                                </ul>

                                <Row>
                                    <Col md={2}>
                                        <Form.Group className="mb-2">
                                            <Form.Label className="small m-1">Icona</Form.Label>
                                            <IconPicker
                                                value={input1.icon}
                                                onChange={e => setInput1({
                                                    ...input1,
                                                    icon: e
                                                })}
                                                buttonStyles={{
                                                    borderRadius: 10,
                                                    width:'100%',
                                                    backgroundColor: '#fff',
                                                    borderColor:'#cbcbcb'
                                                }}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-2">
                                            <Form.Label className="small m-1">Label</Form.Label>
                                            <Form.Control
                                                type="text"
                                                className="rounded10"
                                                placeholder="Label"
                                                value={input1.label}
                                                onChange={e => setInput1({
                                                    ...input1,
                                                    label: e.target.value
                                                })}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-2">
                                            <Form.Label className="small m-1">Descrizione</Form.Label>
                                            <Form.Control
                                                type="text"
                                                className="rounded10"
                                                placeholder="Descrizione"
                                                value={input1.desc}
                                                onChange={e => setInput1({
                                                    ...input1,
                                                    desc: e.target.value
                                                })}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md="auto" className="d-flex justify-content-end align-items-end">
                                        <Button
                                            className="rounded10 mb-2"
                                            disabled={input1.icon.trim()==='' || input1.label.trim()==='' || input1.desc.trim()===''}
                                            onClick={() => {
                                                setModaledit({
                                                    ...modaledit,
                                                    datigenerali: [
                                                        ...modaledit.datigenerali,
                                                        {
                                                            icon: input1.icon,
                                                            label: input1.label,
                                                            desc: input1.desc,
                                                        }
                                                    ]
                                                })
                                                setInput1({
                                                    icon: '',
                                                    label: '',
                                                    desc: '',
                                                })
                                            }}
                                        >
                                            Aggiungi
                                        </Button>
                                    </Col>
                                </Row>
                            </div>


                            <div className="mb-4">
                                <h5 className="fw-bold mb-2">Dati tecnici</h5>

                                <ul>
                                    {
                                        modaledit.datitecnici.length!==0 ?
                                            modaledit.datitecnici.map(a => <li>
                                                {a.label}: <b>{a.desc}</b>
                                                <span>&nbsp;&nbsp;<FaTimes
                                                    className="iconHover"
                                                    size="1.2em"
                                                    color="#ff0000"
                                                    onClick={() => {
                                                        if(window.confirm('Eliminare questa voce?')) {
                                                            setModaledit({
                                                                ...modaledit,
                                                                datitecnici: modaledit.datitecnici.filter(x => x.label!==a.label && x.desc!==a.desc)
                                                            })
                                                        }
                                                    }}
                                                /></span>
                                            </li>)
                                            :
                                            <li>
                                                <i className="fw-bold opacity-50 text-decoration-underline">Nessun dato aggiunto</i>
                                            </li>
                                    }
                                </ul>

                                <Row>
                                    <Col>
                                        <Form.Group className="mb-2">
                                            <Form.Label className="small m-1">Label</Form.Label>
                                            <Form.Control
                                                type="text"
                                                className="rounded10"
                                                placeholder="Label"
                                                value={input2.label}
                                                onChange={e => setInput2({
                                                    ...input2,
                                                    label: e.target.value
                                                })}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-2">
                                            <Form.Label className="small m-1">Descrizione</Form.Label>
                                            <Form.Control
                                                type="text"
                                                className="rounded10"
                                                placeholder="Descrizione"
                                                value={input2.desc}
                                                onChange={e => setInput2({
                                                    ...input2,
                                                    desc: e.target.value
                                                })}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md="auto" className="d-flex justify-content-end align-items-end">
                                        <Button
                                            className="rounded10 mb-2"
                                            disabled={input2.label.trim()==='' || input2.desc.trim()===''}
                                            onClick={() => {
                                                setModaledit({
                                                    ...modaledit,
                                                    datitecnici: [
                                                        ...modaledit.datitecnici,
                                                        {
                                                            label: input2.label,
                                                            desc: input2.desc,
                                                        }
                                                    ]
                                                })
                                                setInput2({
                                                    label: '',
                                                    desc: '',
                                                })
                                            }}
                                        >
                                            Aggiungi
                                        </Button>
                                    </Col>
                                </Row>
                            </div>

                            <div className="mb-4">
                                <h5 className="fw-bold mb-2">Foto e video</h5>

                                <ul>
                                    {
                                        modaledit.foto.length!==0 ?
                                            modaledit.foto.map(a => <li>
                                                <a href={a} target="_blank" rel="noreferrer">LINK</a>
                                                <span>&nbsp;&nbsp;<FaTimes
                                                    className="iconHover"
                                                    size="1.2em"
                                                    color="#ff0000"
                                                    onClick={() => {
                                                        if(window.confirm('Eliminare questa voce?')) {
                                                            setModaledit({
                                                                ...modaledit,
                                                                foto: modaledit.foto.filter(x => x!==a)
                                                            })
                                                        }
                                                    }}
                                                /></span>
                                            </li>)
                                            :
                                            <li>
                                                <i className="fw-bold opacity-50 text-decoration-underline">Nessuna foto aggiunta</i>
                                            </li>
                                    }
                                </ul>

                                <Row>
                                    <Col>
                                        <Form.Group className="mb-2">
                                            <Form.Label className="small m-1">Foto</Form.Label>
                                            <Form.Control
                                                type="link"
                                                className="rounded10"
                                                placeholder="Link foto"
                                                value={input3}
                                                onChange={e => setInput3(e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md="auto" className="d-flex justify-content-end align-items-end">
                                        <Button
                                            className="rounded10 mb-2"
                                            disabled={input3.trim()===''}
                                            onClick={() => {
                                                setModaledit({
                                                    ...modaledit,
                                                    foto: [
                                                        ...modaledit.foto,
                                                        input3,
                                                    ]
                                                })
                                                setInput3('')
                                            }}
                                        >
                                            Aggiungi
                                        </Button>
                                    </Col>
                                </Row>

                                <Form.Group className="mb-2">
                                    <Form.Label className="small m-1">Video YouTube</Form.Label>
                                    <Form.Control
                                        type="link"
                                        className="rounded10"
                                        placeholder="Link video YouTube"
                                        value={modaledit.video}
                                        onChange={e => setModaledit({
                                            ...modaledit,
                                            video: e.target.value,
                                        })}
                                    />
                                </Form.Group>

                            </div>

                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="secondary"
                                onClick={() => setModaledit()}
                                className="rounded10"
                            >
                                Annulla
                            </Button>
                            <Button
                                variant="primary"
                                className="rounded10"
                                disabled={modaledit.titolo.trim()==='' || modaledit.fotoCover.trim()==='' || modaledit.testo.trim()===''}
                                onClick={() => {
                                    firebase.firestore().collection('realizzazioni').doc(modaledit.id).set(modaledit).then(() => {
                                        setModaledit()
                                    }).catch(() => {})
                                }}
                            >
                                Aggiorna
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    :
                    null
            }

        </div>
    )


    function cardRealizzazione(data) {
        return (
            <Col xs={12} sm={12} md={6} lg={6} xl={4} className="mb-4 text-center py-5" key={data.id}>
                <div className="d-flex justify-content-center mb-4">
                    <div
                        style={{
                            backgroundImage:`url(${data.fotoCover})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            width:'13em',
                            height: '13em'
                        }}
                        className="boxImgRealizzazioni"
                        onClick={() => {
                            window.location.assign('/realizzazioni/' + data.id)
                        }}
                    />
                </div>

                <div className="mb-3">
                    <h4 className="fw-bold">{data.titolo}</h4>
                </div>
                <div className="text-center">
                    {
                        data.datigenerali ?
                            data.datigenerali.map(a => <div className="d-flex justify-content-center align-items-center">
                                <div>
                                    <IconPickerItem icon={a.icon} size={28} color="#000" />
                                </div>
                                <div>
                                    <span>&nbsp;&nbsp;{a.label}: {a.desc}</span>
                                </div>
                            </div>)
                            :
                            null
                    }
                </div>
                <div>
                    <FaPencilAlt
                        size="1.25em"
                        color="#0000ff"
                        className="mx-1 iconHover"
                        onClick={() => {
                            setModaledit(data)
                        }}
                    />
                    <FaTimes
                        size="1.7em"
                        color="#ff0000"
                        className="mx-1 iconHover"
                        onClick={() => {
                            if(window.confirm('Eliminare definitivamente la realizzazione ' + data.titolo + ' ?\n\nN.B.: L\'operazione è irreversibile.')) {
                                firebase.firestore().collection('realizzazioni').doc(data.id).delete().then(() => {}).catch(() => {})
                            }
                        }}
                    />
                </div>
            </Col>
        )
    }

}
