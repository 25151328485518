import {Navbar} from "../../Navbar/Navbar";
import {Col, Container, Row} from "react-bootstrap";
import {Footer} from "../../Footer/Footer";
import {useEffect, useState} from "react";
import firebase from "firebase";
import {IconPickerItem} from "react-fa-icon-picker";
import {MetaDecorator} from "../../utils/MetaDecorator";


export function Realizzazioni() {

    const [dataDwl, setDataDwl] = useState([])
    useEffect(() => {
        firebase.firestore().collection('realizzazioni').onSnapshot((snapshot) => {
            let res = []
            snapshot.forEach((doc) => res.push(doc.data()))
            setDataDwl(res)
        })
    }, [])

    return (
        <div>
            <MetaDecorator
                title="Realizzazioni"
                description="Tutte le lavorazioni in corso e concluse firmate MGM solar."
                keywords="realizzazioni, lavori, opere, esempi pannelli, esempi, esempio, lavoro"
            />
            <Navbar />
            <Container fluid className="boxImgCover" style={{height:'60vh', backgroundImage:'url("https://i.ibb.co/gDhJdfs/realizzazioni-cover.jpg")', backgroundSize:'cover', backgroundPosition:'center'}} />
            <Container className="py-5">
                <div className="text-center mb-5 pt-3">
                    <h1 className="fw-bold">Realizzazioni</h1>
                </div>
                <div>
                    <Row>
                        {
                            dataDwl!==[] ?
                                dataDwl.map(a => cardRealizzazione(a))
                                :
                                null
                        }
                    </Row>
                </div>
            </Container>

            <Footer />
        </div>
    )

    function cardRealizzazione(data) {
        return (
            <Col xs={12} md={6} lg={6} xl={4} className="mb-4 text-center p-5" key={data.id}>
                <div className="cardRealizzazione"
                     onClick={() => {
                         window.location.assign('/realizzazioni/' + data.id)
                     }}
                >
                    <div className="d-flex justify-content-center mb-4">
                        <div
                            style={{
                                backgroundImage:`url(${data.fotoCover})`,
                            }}
                            className="imgCardRealizzazione"
                        />
                    </div>

                    <div className="px-4 pb-4">
                        <div className="mb-4">
                            <h4 className="fw-bold">{data.titolo}</h4>
                        </div>
                        <div className="text-center small pb-2">
                            {
                                data.datigenerali ?
                                    data.datigenerali.map(a => <Row className="px-4">
                                        <Col md={3} className="d-flex justify-content-center align-items-center">
                                            <IconPickerItem icon={a.icon} size={28} color="#fff" />
                                        </Col>
                                        <Col md={9} style={{lineHeight:'1em'}} className="text-start d-flex justify-content-start align-items-center">
                                            <p>{a.label}<br/><b>{a.desc}</b></p>
                                        </Col>
                                    </Row>)
                                    :
                                    null
                            }
                        </div>
                    </div>
                </div>
            </Col>
        )
    }
}
