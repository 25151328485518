import {Navbar} from "../../Navbar/Navbar";
import {Col, Container, Row} from "react-bootstrap";
import {Footer} from "../../Footer/Footer";
import {MetaDecorator} from "../../utils/MetaDecorator";


export function FotovoltaicoSuParcheggio() {

    return (
        <div>
            <MetaDecorator
                title="Fotovoltaico su Parcheggio"
                description="Le pensiline fotovoltaiche svolgono una duplice funzione: produrre energia pulita creando aree relax protette e ombreggiate o aree di parcheggio e ricarica per le auto."
                keywords="fotovoltaico, parcheggio, fotovoltaico su parcheggio, fotovoltaico auto, aziende"
            />
            <Navbar />
            <Container fluid className="boxImgCover" style={{height:'60vh', backgroundImage:'url("https://i.ibb.co/kDgr2gx/7.jpg")', backgroundSize:'cover', backgroundPosition:'center'}} />
            <Container className="py-5">
                <div className="text-center mb-5 pt-3">
                    <h1 className="fw-bold">Fotovoltaico su Parcheggio</h1>
                </div>
                <div className="py-5">
                    <Row className="mt-5">
                        <Col xs={12} lg={5} className="text-start d-flex justify-content-center align-items-center order-2 order-lg-1">
                            <img
                                src="https://i.ibb.co/CshMnyn/pensiline-1.jpg"
                                alt="Chi Siamo"
                                className="img4corners imgBig"
                            />
                        </Col>
                        <Col xs={12} lg={7} className="text-center text-lg-end d-flex justify-content-start align-items-center order-1 order-lg-2">
                            <div className="mb-5 mb-lg-0">
                                <h2 className="fw-bold mb-5">Scopri le nostre pensiline fotovoltaiche</h2>
                                <p className="mb-5">
                                    <p>Le pensiline fotovoltaiche svolgono una duplice funzione: produrre energia pulita  creando aree relax protette e ombreggiate o arre di parcheggio e ricarica per le auto.</p>
                                    <br />
                                    <p>I nostri sistemi sono realizzati su misura in base ai valori di carico neve, vento e sisma previsti dalle normative locali e internazionali.</p>
                                    <br />
                                    <p>Grazie ai moduli fotovoltaici, le nostre pergole garantiscono un beneficio economico che ti permetterà di ripagare il tuo investimento in pochi anni.</p>
                                    <br />
                                    <p>Queste strutture garantiscono elevate prestazioni in termini di durezza e resistenza agli agenti atmosferici e risultano particolarmente pratiche per la pulizia e la manutenzione.</p>
                                    <br />
                                    <p>Ogni pensilina può essere realizzata senza bisogno di opere murarie o interventi edilizi.</p>
                                </p>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="py-5">
                    <Row className="mt-5">
                        <Col xs={12} lg={7} className="text-center text-lg-start d-flex justify-content-start align-items-center">
                            <div className="mb-5 mb-lg-0">
                                <h2 className="fw-bold mb-5">Quali sono i vantaggi?</h2>
                                <p className="mb-5">
                                    <p>I vantaggi di una pensilina fotovoltaica sono numerosi, sia che essa venga installata in un’abitazione residenza che in aree pubbliche:</p>
                                    <br />
                                    <ul>
                                        <li>creazione di un parcheggio per le auto o di un’area di relax-svago in giardino</li>
                                        <li>impiego della copertura per la produzione di energia pulita</li>
                                        <li>non necessita di opere murarie o interventi edilizi</li>
                                        <li>elevate prestazioni di durevolezza e resistenza agli agenti esterni</li>
                                        <li>facile manutenzione e pulizia dei pannelli</li>
                                        <li>perfetta integrazione architettonica senza ostruire spazi</li>
                                        <li>risparmia e abbatti la tua bolletta</li>
                                    </ul>
                                </p>
                            </div>
                        </Col>
                        <Col xs={12} lg={5} className="text-end d-flex justify-content-center align-items-center">
                            <img
                                src="https://i.ibb.co/q1wCv0w/pensiline1.jpg"
                                alt="Chi Siamo"
                                className="img4corners imgBig"
                            />
                        </Col>
                    </Row>
                </div>
                <div className="py-5">
                    <Row className="mt-5">
                        <Col xs={12} lg={5} className="text-start d-flex justify-content-center align-items-center order-2 order-lg-1">
                            <img
                                src="https://i.ibb.co/5Lz5Zzs/pensiline-3.jpg"
                                alt="Chi Siamo"
                                className="img4corners imgBig"
                            />
                        </Col>
                        <Col xs={12} lg={7} className="text-center text-lg-end d-flex justify-content-start align-items-center order-1 order-lg-2">
                            <div className="mb-5 mb-lg-0">
                                <h2 className="fw-bold mb-5">Perché scegliere le pensiline MGM Solar?</h2>
                                <p className="mb-5">
                                    <p>A garanzia della qualità e sicurezza di ogni tettoia, i tecnici Mgm Solar provvedono a fornire tutta la documentazione necessaria:</p>
                                    <br />
                                    <ul className="rtl">
                                        <li>Relazione di calcolo della struttura</li>
                                        <li>Relazione sui materiali impiegati</li>
                                        <li>Elaborato grafico di progetto</li>
                                        <li>Relazione sismica (se necessaria)</li>
                                        <li>Piano di manutenzione</li>
                                    </ul>
                                </p>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="py-5">
                    <h2 className="mb-5 fw-bold text-center">Pensiline su misura per ogni esigenza</h2>
                    <Row className="d-flex justify-content-center align-items-center">
                        <Col xs={12} lg="auto" className="mb-5 text-center">
                            <img
                                src="https://i.ibb.co/k8mLqkW/pensilineazienda.jpg"
                                alt="img_servizio_01"
                                className="mb-4 img4corners"
                            />
                            <div>
                                <h3 className="fw-bold">Azienda</h3>
                            </div>
                        </Col>
                        <Col xs={12} lg="auto" className="mb-5 text-center">
                            <img
                                src="https://i.ibb.co/JQByrT9/pensilinecasa.jpg"
                                alt="img_servizio_01"
                                className="mb-4 img4corners"
                            />
                            <div>
                                <h3 className="fw-bold">Casa</h3>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>

            <Footer />
        </div>
    )
}
